var inAnime = false;

$.fn.advanceSlide = function (to, callback) {

    var $obj = $(this);
    var isVisible = $obj.is(':visible');
    var callbackfn = function () {
        if (callback && typeof callback === 'function') {
            return callback();
        }
        return false;
    }
    if ($obj.data('height') == undefined || $obj.data('height') == 0) {
        if (!isVisible) {
            $obj.css({display: 'block', opacity: 0, position: 'absolute'});
        }
        $obj.data('height', $obj.height());
        if (!isVisible) {
            $obj.css({display: 'none', opacity: '', position: ''});
        }
    }

    var objH = $obj.data('height');
    if (inAnime == false) {
        inAnime = true;
        if (to == 'show' && !isVisible) {
            $obj.stop().css({display: 'block', overflow: 'hidden', height: 0}).animate({height: objH}, function () {
                $obj.css({overflow: '', height: ''});
                inAnime = false;
                callbackfn();
            });
        } else if (to == 'hide' && isVisible) {
            $obj.stop().css({display: 'block', overflow: 'hidden', height: objH}).animate({height: 0}, function () {
                $obj.css({display: 'none', overflow: '', height: ''});
                inAnime = false;
                callbackfn();
            });
        } else {
            inAnime = false;
        }
    }
};

// This example displays an address form, using the autocomplete feature
// of the Google Places API to help users fill in the information.

var placeSearch;

function initialize_google_place(type) {

	var prefix = ( type == 'delivery' )? '':type;

	var componentElem = {
	  street_number: {target : '#'+prefix+'adresseLiv', length : 'short_name'},
	  route: {target : '#'+prefix+'adresseLiv', length : 'long_name'},
	  locality: {target : '#'+prefix+'ville', length : 'long_name'},
	  country: {target : '#'+prefix+'pays', length : 'short_name'},
	  postal_code: {target : '#'+prefix+'codeP', length : 'short_name'}
	};

	if ($('#'+prefix+'adresseLiv').length == 0) {
		var componentElem = {
		  street_number: {target : 'input.'+prefix+'adresseLiv', length : 'short_name'},
		  route: {target : 'input.'+prefix+'adresseLiv', length : 'long_name'},
		  locality: {target : 'input.'+prefix+'ville', length : 'long_name'},
		  country: {target : 'select.'+prefix+'pays', length : 'short_name'},
		  postal_code: {target : 'input.'+prefix+'codeP', length : 'short_name'}
		};
	}

	// Create the autocomplete object, restricting the search
	// to geographical location types.
	var searchElem = document.getElementById(prefix+'autocomplete_addr');
	var option = {
		types: ['geocode']
		//,componentRestrictions: { country: [<?php echo $liste_pays_iso; ?>] }
	};

	geocodeur = new google.maps.Geocoder();

	var autocompleteElem = new google.maps.places.Autocomplete( (searchElem), option);
	// When the user selects an address from the dropdown,
	// populate the address fields in the form.
	google.maps.event.addListener(autocompleteElem, 'place_changed', function(e) {
		fillInAddressGoogle(autocompleteElem, componentElem, true,'#'+prefix+'autocomplete_addr','.noaddr.'+type, '.elem_addr_autocomplete.'+type, 'select#'+prefix+'pays');
		showGlobalGoogle('#'+prefix+'autocomplete_addr','.noaddr.'+type,'.elem_global.'+type, 'finish', '.elem_addr_autocomplete.'+type);
		$('#'+prefix+'autocomplete_addr').trigger('place_changed');
		var carte = $('#'+prefix+'autocomplete_addr').attr('data-carte');
		// On regarde si lors du changement de l'adresse du champ en question on veux charger une carte avec la position de la recherche
		if (carte != '') {
			var adresse = $('#'+prefix+'autocomplete_addr').val();
			geocodeur.geocode( { address : adresse }, function(results, status) {
				// Si l'utilisateur ne saisit rien on se remet à la position initiale (on remet la premiere carte et tous les magasins)
				if (status == google.maps.GeocoderStatus.OK){
					if ($( '#' + carte ).length) {					
						$('#' + carte).css('display', 'block');
						var maCarte;
						maCarte = new google.maps.Map(document.getElementById(carte), {
							styles : google_map_style
						});

						var latlng = new google.maps.LatLng(results[0].lat, results[0].lng);
						// Positionnement de la carte
						maCarte.setCenter(results[0].geometry.location);
						// Zoom sur la carte
						maCarte.setZoom(13);
						// Le marqueur sur la carte
						var marqueur = new google.maps.Marker({
							map: maCarte,
							position: results[0].geometry.location,
							icon: path_relative_root + 'img/maps/marker.png'
						});
					}
				}
			});
		}
	});

	commonAction(searchElem, autocompleteElem );

	$('.wrapper_form_adresse').on({
		'click':function(){
			
			$('.elem_global.'+type).advanceSlide('show');
			$('.noaddr.'+type).css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
				$('.noaddr.'+type).css({display : 'none',opacity : ''});
				$('.elem_addr_autocomplete.'+type).advanceSlide('show');
				$('#'+prefix+'autocomplete_addr').hide();
				$('#'+prefix+'adresseLiv').focus();
			});
			
		}
	},'.noaddr.'+type);
	$('#'+prefix+'autocomplete_addr').on('keyup',function(e){
		showGlobalGoogle(e.target,'.noaddr.'+type,'.elem_global.'+type, '', '.elem_addr_autocomplete.'+type);
	});
	
	$('#'+prefix+'autocomplete_addr').on('click',function(e){
		$('.noaddr.'+type).addClass('show').css({display : 'block', opacity : 0}).animate({opacity: 1},function(){
			$('.noaddr.'+type).css({opacity : ''});
		});
	});

	$('#'+prefix+'autocomplete_addr').on('blur',function(e){
		$('.noaddr.'+type).css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
			$('.noaddr.'+type).css({display : 'none',opacity : ''});
		});
	});

}

function initialize_map(type) {
	var componentForm_map = {
	  street_number: {target : '.choix_relais_relais'+type+' #street', length : 'short_name'},
	  route: {target : '.choix_relais_relais'+type+' #street', length : 'long_name'},
	  locality: {target : '.choix_relais_relais'+type+' #city', length : 'long_name'},
	  country: {target : '.choix_relais_relais'+type+' #country', length : 'short_name'},
	  postal_code: {target : '.choix_relais_relais'+type+' #zip', length : 'short_name'}
	};
	// Create the autocomplete object, restricting the search
	// to geographical location types.
	var searchElem = $(".choix_relais_relais" + type + " .autocomplete_addr_map" )[0];
	var option = {
	types: ['geocode']
	//,componentRestrictions: { country: [<?php echo $liste_pays_iso; ?>] }
	};

	var autocomplete_map = new google.maps.places.Autocomplete( (searchElem), option);
	// When the user selects an address from the dropdown,
	// populate the address fields in the form.
	google.maps.event.addListener(autocomplete_map, 'place_changed', function(e) {
		fillInAddressGoogle(autocomplete_map, componentForm_map, false, '.choix_relais_relais'+type+' .autocomplete_addr_map', '', '', '#zip');
		$('.choix_relais_relais'+type+' .autocomplete_addr_map').trigger('place_changed');
		var address = $('.choix_relais_relais'+type+' #street').val();
		var zipcode = $('.choix_relais_relais'+type+' #zip').val();
		var city = $('.choix_relais_relais'+type+' #city').val();
		var country = $('.choix_relais_relais'+type+' #country').val();
		if( zipcode != '' ){
			loadMapDelivery(address+'|'+zipcode+'|'+city+'|'+country, type);
		}
	});

	commonAction(searchElem, autocomplete_map );
}

function commonAction(searchElem, autcompleteElem ){
	$(searchElem).on({
		'focus':function(){
			geolocate(autcompleteElem);
		}
	});
}

function getKeys(obj) {

	var keys, key;

	arrayKeys = [];

	for (key in obj) {

		if (obj.hasOwnProperty(key)) {

			arrayKeys.push(key);
		}
	}

	return arrayKeys;
}

// [START region_fillform]
function fillInAddressGoogle(resultGoogle, listComponent, activAnime, adrId , noadr, adrDetai, verifTarget) {
// Get the place details from the autocomplete object.

	var place = resultGoogle.getPlace();
	if(typeof place == 'undefined'){
		$(adrId).hide();
		$(adrDetai).advanceSlide('show');
		return false;
	}
	var componentLng = place.address_components.length;
	var finalResult = {}; 
	var getShortCodeCountry;
	var displayed = false;
	var listComponentToArray = getKeys(listComponent);
	var listComponentLength = listComponentToArray.length;
	var listeTypeResult = {};
	var listeElemResult = {};
	var showAdrDetai = false;

	for(i=0; i < componentLng; i++){
		var addressType = place.address_components[i].types[0];
		listeTypeResult[addressType] = i;
		if (listComponent[addressType]) {
			var adressValue = place.address_components[i][listComponent[addressType].length];
			listeElemResult[addressType] = adressValue;
			if(addressType == 'country'){
				getShortCodeCountry = adressValue;
			}
			var valTarget = listComponent[addressType].target;
			if(finalResult[valTarget] != undefined){
				finalResult[valTarget] = finalResult[valTarget] +' '+ adressValue;
			}else{
				finalResult[valTarget] = adressValue;
			}
		}
	}
	if (listeTypeResult.postal_code == undefined && listeTypeResult.administrative_area_level_2 != undefined) {
		finalResult[listComponent.postal_code.target] = place.address_components[listeTypeResult.administrative_area_level_2][listComponent.postal_code.length];
	}

	for(l=0; l < listComponentLength; l++){
		var target = listComponentToArray[l];
		var targetList = listComponent[target].target;


		if(finalResult[targetList] == undefined){
			finalResult[targetList] = '';
		}
		if(listeElemResult[target] == undefined){
			showAdrDetai = true;
			$(adrId).hide();
			$(adrDetai).advanceSlide('show');
		}
	}
	var is_dom = ( $.inArray( getShortCodeCountry,['GP','GF','MQ','RE','YT']) != -1 );
	var is_tom = ( $.inArray( getShortCodeCountry,['PM','MF','BL','WF','TF','NC','PF']) != -1 );

	getShortCodeCountry = (is_dom) ? 'DO' : (is_tom) ? 'TO' : getShortCodeCountry;

	var array_corres_code_valide = { BE: 'B', LU: 'L', MC: 'FR', CH: 'S', FR: 'FR', DO :'DO', TO : 'TO'};

	if(array_corres_code_valide[getShortCodeCountry] != undefined){
		getShortCodeCountry = array_corres_code_valide[getShortCodeCountry];
	}

	var finalArray = getKeys(finalResult);
	var finalLng = finalArray.length;
	for(j = 0; j < finalLng; j++){
		if(activAnime && verifTarget != undefined){
			if( $(finalArray[j]).is(verifTarget)){

				if(verifTarget.indexOf('pays') > 0){

					$(adrId).data('google-val',getShortCodeCountry);
					if($('option[data-name='+getShortCodeCountry+']',finalArray[j]).length){
						$('option',finalArray[j]).prop('selected',null);
						$('option[data-name='+getShortCodeCountry+']',finalArray[j]).prop('selected','selected').trigger('change');
					}else{
						$('option',finalArray[j]).prop('selected',null);
						$('option[data-name="null"]',finalArray[j]).prop('selected','selected').trigger('change');
						if(displayed == false){
							displayed = true;
							$(noadr).css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
								$(noadr).css({display : 'none',opacity : ''});
							});
							setTimeout(function(){
								$('input', adrDetai).trigger('blur');
							},1000);
						}
					}
				}
			}else{
				$(finalArray[j]).val(finalResult[finalArray[j]]).trigger('blur');
			}
		}else{
			$(finalArray[j]).val(finalResult[finalArray[j]]);
		}

	}
	if(verifTarget != undefined){
		if(verifTarget.indexOf('pays') > 0){
			$(adrId).data('google-val',getShortCodeCountry);
		}
		if(verifTarget.indexOf('zip') > 0 && $(adrId).length > 0){
			$(adrId).data('google-val',getShortCodeCountry+'|'+$(verifTarget).val());
		}
	}

	if(activAnime){
		var elemLength = getKeys(listComponent).length;
		for(k = 0; k < elemLength; k++ ){
			var currentTarget = listComponent[getKeys(listComponent)[k]].target;

			if($.inArray( currentTarget, finalArray ) == -1 && displayed == false){
				displayed = true;
				$(noadr).css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
					$(noadr).css({display : 'none',opacity : ''});
				});
				setTimeout(function(){
					$('input', adrDetai).trigger('blur');
				},1000);
			}
		}
	}

	$(noadr).css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
		$(noadr).css({display : 'none',opacity : ''});
	});
	
}
// [END region_fillform]


// [START region_geolocation]
// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate(resultGoogle) {
  if (navigator.geolocation && navigator.userAgent.indexOf('Firefox') == -1) {
	navigator.geolocation.getCurrentPosition(function(position) {
	  var geolocation = new google.maps.LatLng(
		  position.coords.latitude, position.coords.longitude);
		  resultGoogle.setBounds(
			new google.maps.LatLngBounds(geolocation, geolocation)
		  );
	});
  }
}
// [END region_geolocation]


function showGlobalGoogle(target, noadr, elemGlob, addrExist, adrDetai){
	var elemGoole = $('.pac-item','.pac-container');
	var resultLength = $('.pac-item','.pac-container').length;
	if($(target).prop('value').length >= 2 || elemGoole.length ){
		$(elemGlob).advanceSlide('show');
	}
}
