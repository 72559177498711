if (!Object.create) {
    Object.create = (function(){
        function F(){}

        return function(o){
            if (arguments.length != 1) {
                throw new Error('Object.create implementation only accepts one parameter.');
            }
            F.prototype = o;
            return new F();
        };
    })();
}

if(typeof String.prototype.trim !== 'function') {
  String.prototype.trim = function() {
    return this.replace(/^\s+|\s+$/g, '');
};
}

if (typeof WPTooltip == "undefined") {
//    alert ("'js/wp_api.js' not included");
}

/* WPApi extends WPTooltip*/
function WPApi() {}
WPApi.prototype = mixin(Object.create(WPTooltip.prototype));//Add emitter methods

/*
 * function init
 * initialize calass
*/
WPApi.prototype.init = function(settings) {
    this.initTooltip(settings);
    this.resetAllEvents();
    this.addListeners();
    this.scanForErrors();
};

/*
 * repport a javascript error
 *
*/
WPApi.prototype.repportError = function(message, method) {};

/*
 * Abstract function
 * must be implemented in inherited objects
*/
WPApi.prototype.resetAllEvents = function() {};

/*
 * Abstract function
 * must be implemented in inherited objects
*/
WPApi.prototype.addListeners = function() {};

WPTooltip.prototype.setOption = function(key, value) {
    this.settings[key] = value;
};

/*
 * scan for all errors in current page
 * and initialize tooltip with error message
*/
WPApi.prototype.scanForErrors = function () {
    var that = this;
    $(".img_error").each (function() {
        var id = this.id.replace('iconErr', '');
        if ($("#textErr" + id).length > 0) {
            that.showError(id, $("#textErr" + id).html());
        }
    });

};

/*
 * verify a password field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkPassword = function(elementId) {
    return this.checkAlpha(elementId, "login_mdp");
};

/*
 * verify last name text field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkLastName = function(elementId) {
    return this.checkAlpha(elementId, "login_nom", true );
};

/*
 * verify first name text field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkFirstName = function(elementId) {
    return this.checkAlpha(elementId, "login_prenom", true );
};

/*
 * verify VAT text field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkVAT = function(elementId) {
    this.hideTooltip();
    if ($("#" + elementId).val().length > 13 ) {
        var msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=login_vat');
        this.showError (elementId, msg);
        return false;
    }
    return this.checkAlpha(elementId, "login_vat");
};

/*
 * verify clientTaxNbr text field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
 * Need to be defined so true for the moment...
*/
WPApi.prototype.checkClientTaxNbr = function(elementId) {
    this.hideTooltip();
    return true;
    if ($("#" + elementId).val().length < 7) {
        var msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=login_taxnbr');
        this.showError (elementId, msg);
        return false;
    }
    return this.checkAlpha(elementId, "login_taxnbr");
};

/*
 * verify nick name text field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkNickName = function(elementId) {
    return this.checkAlpha(elementId, "login_pseudo");
};

/*
 * verify alpha-numeric field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
 *      String field_name - the field name in the translation table
 *      boolean alphaOnly|default:false - if true check only for letters
*/
WPApi.prototype.checkAlpha = function(elementId, field_name, alphaOnly) {

    if (typeof alphaOnly == "undefined") {
        alphaOnly = false;
    }
    var msg;
    this.hideTooltip();

    // check is empty
    if ($("#" + elementId).val().trim() == '') {
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + field_name);
        this.showError (elementId, msg);
        return false;
    }

    var pattern = "^[\\p{L}-\ ,_\/.']+$";
    if (! alphaOnly) {
        pattern = "^[\\p{L}-\ ,_\/.0-9']+$";
    }

    var unicodeWord = XRegExp(pattern);
    var isOk = unicodeWord.test($('#' + elementId).val());
    if (isOk) {
        this.clearError(elementId);
        return true;
    }
    if (alphaOnly)
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + field_name);
    else
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + field_name);

    this.showError (elementId, msg);

    return false;
};

WPApi.prototype.checkNumber = function(elementId, field_name, nb, inter) {

    var msg;
    this.hideTooltip();

    // check is empty
    if ($("#" + elementId).val().trim() == '') {
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + field_name);
        this.showError (elementId, msg);
        return false;
    }

    if (typeof nb == "undefined" | nb == false) {
        if(typeof inter == "undefined" | inter == false){
            var pattern = "^[0-9]+$";
        } else {
            var pattern = "^[0-9\+]+$";
        }
    } else {
        if(typeof inter == "undefined" | inter == false){
            var pattern = "^[0-9]{"+nb+"}$";
        } else {
            var pattern = "^[0-9\+]{"+nb+"}$";
        }
    }

    var unicodeWord = XRegExp(pattern);
    var isOk = unicodeWord.test($('#' + elementId).val());
    if (isOk) {
        this.clearError(elementId);
        return true;
    }

    msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + field_name);

    this.showError (elementId, msg);
    return false;
};

WPApi.prototype.checkAlphaSpeChar = function(elementId, field_name, alphaOnly) {
    if (typeof alphaOnly == "undefined") {
        alphaOnly = false;
    }
    var msg;
    this.hideTooltip();
    // check is empty
    if ($("#" + elementId).val().trim() == '') {
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + field_name);
        this.showError (elementId, msg);
        return false;
    }

    var pattern = "^[\\p{L}-\ ,_\/.']+$";
    if (! alphaOnly) {
        pattern = "^[\\p{L}-\ ,_\/.'0-9]+$";
    }

    var unicodeWord = XRegExp(pattern);
    var isOk = unicodeWord.test($('#' + elementId).val());
    if (isOk) {
        this.clearError(elementId);
        return true;
    }
    if (alphaOnly)
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + field_name);
    else
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + field_name);

    this.showError (elementId, msg);
    return false;
};

/*
 * check if a textarea field  is not empty
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkTextarea = function (elementId) {
    var that = this;
    if($("#" + elementId).val().length > 2){
        this.clearError(elementId);
        return true;
    }
    msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=contact_field_message');
    this.showError (elementId, msg);
    return false;
};
/*
 * compare 2 text fields by value and get the translated error message
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
 *      String compareWith - id of the elemement
 *          that elemrntId will be compared
*/
WPApi.prototype.compareText = function(elementId, compareWith, msg) {
    this.hideTooltip();
    if ($("#" + compareWith).val() != '' && $("#" + elementId).val() != $("#" + compareWith).val()) {
        this.showError (elementId, msg);
        return false;
    } else {
        this.clearError(elementId);
        return true;
    }
    return false;
};
/*
 * compare 2 password fields by value and get the translated error message
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
 *      String compareWith - id of the elemement
 *          that elemrntId will be compared
*/
WPApi.prototype.comparePassword = function(elementId, compareWith) {
    var msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_same_mdp_confirm&arg2=login_mdp_conf');
    return this.compareText(elementId, compareWith, msg);
};

/*
 * compare 2 email fields by value and get the translated error message
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.compareEmail = function(elementId, compareWith) {
    var msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_same_email_confirm&arg2=login_email_verif');
    return this.compareText(elementId, compareWith, msg);
};


/*
 * verify nick name text field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkUrl = function(elementId, optional) {
    var msg;
    this.hideTooltip();

    var pattern = "^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$";

    var unicodeWord = XRegExp(pattern);

    var isOk = unicodeWord.test($('#' + elementId).val());
    if(optional === true && $elem.val().length == 0 ){
        this.clearError(elementId);
        return true;
    }
    if (isOk) {
        this.clearError(elementId);
        return true;
    }

    msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_url');

    this.showError (elementId, msg);
    return false;
};

/*
 * check if a text field is a valid emai address
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkEmail = function (elementId) {
    var that = this;
    this.hideTooltip();
    $.ajax({url: path_relative_root + 'ajax_check_email.php?email='+ $("#" + elementId).val()+"&err_code=1"})
     .done(function(data) {
        if (data.trim() != 'ok') {
            that.showError(elementId, data);
            that.showTooltip("iconErr" + elementId, false, that.getMessageWithTitle(elementId, data));
            return false;
        } else {
            that.clearError(elementId);
            return true;
        }
    });
     return false;
};

/*
 * check if a text field  is a valid phone number
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkPhoneNumber = function (elementId, field_name, is_mobile, facultatif) {

    if (typeof field_name === "undefined") {
        field_name = 'login_telephone';
    }

    var $elem = $('#'+elementId);

     this.hideTooltip();

    flag = verif_telephone(elementId, field_name, is_mobile);
    if(facultatif === true && $elem.val().length < 2 ){
        this.clearError(elementId);
        return true;
    }
    if (!flag) {
        this.showError (elementId, $("#erreur_msg").html());
        return false;
    } else {
        this.clearError(elementId);
        return true;
    }
    return false;
};

WPApi.prototype.checkPhoneNumberAuto = function (elementId, field_name, is_mobile, facultatif) {

    if (typeof auto_completion_tel === "undefined") {
        return this.checkPhoneNumber(elementId, field_name, is_mobile, facultatif);
    }

    var msg = '';

    if(document.getElementById("telephone") && document.getElementById("mobile") === null){
        msg = ajax_file('ajax_sprintf.php?arg1=js_error_phone_mobile');
    } else {
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + field_name);
    }

    this.hideTooltip();

    var isOk = verif_telephone(elementId, field_name, is_mobile);

    if((facultatif === true && document.getElementById(elementId).value === '') || (typeof phone_mandatory !== "undefined" && phone_mandatory !== true && document.getElementById(elementId).value === '')){
        this.clearError(elementId);
        return true;
    }

    if (isOk) {

        if( document.getElementById('billtelephone') !== null ){
            if( document.getElementById('wrapper_bill') !== null && document.getElementById('wrapper_bill').style.display === 'block' && $('#telephone').intlTelInput("isValidNumber")){
                if(document.getElementById("telephoneHidden").value !== ''){
                    $('#billtelephone').val(document.getElementById("telephoneHidden").value);
                } else {
                    $('#billtelephone').val(document.getElementById("mobileHidden").value);
                }
            } else {
                $('#billtelephone').val('');
            }
        }

        this.clearError(elementId);
        return true;
    } else {
		$('#billtelephone').val('');
	}

    this.showError(elementId, msg);

    return false;
};

WPApi.prototype.checkBirthDate = function (elementId_J, elementId_M, elementId_A, field_name) {

    if (typeof field_name == "undefined") {
        field_name = 'login_naissance';
    }

    var jour = $("#" + elementId_J).val();
    var mois = $("#" + elementId_M).val();
    var annee = $("#" + elementId_A).val();

    if (jour != '' || mois != '' || annee != '') {
        var regDateVente= new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        var flag = regDateVente.exec(jour+'/'+mois+'/'+annee);
        var nb_j_mois_annee = 31;

        var verif_j = true;
        var verif_m = true;
        var verif_a = true;

        if(mois != '' && annee != ''){
            var m = [31,28,31,30,31,30,31,31,30,31,30,31];
            if (mois != 2) {
                nb_j_mois_annee = m[mois - 1];
            } else if (annee%4 != 0){
                nb_j_mois_annee = m[1];
            } else if (annee%100 == 0 && annee%400 != 0){
                nb_j_mois_annee = m[1];
            }else{
                nb_j_mois_annee = m[1] + 1;
            }
        }

        if(jour < 1 || jour > nb_j_mois_annee) {
            verif_j = false;
        }

        if(mois < 1 || mois > 12) {
            verif_m = false;
        }

        if(annee < 1900) {
            verif_m = false;
        }

        if (!flag || !verif_j || !verif_m || !verif_a) {
            this.showError (elementId_A, $("#erreur_msg").html());
            return false;
        } else {
            this.clearError(elementId_A);
            return true;
        }
    } else {
        this.clearError(elementId_A);
        return true;
    }
    return false;
};

/*
 * check if a text field  is a valid postal address
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkAddress = function (elementId) {
    var that = this;

    return this.checkAlphaSpeChar(elementId, 'login_adresse', false);

};

/*
 * check if a text field  is a valid postcode
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkPostalCode = function (elementId) {

    this.hideTooltip();
    var nb = 5;

    var country_4_digits = [ 'B', 'BE', 'L', 'LU', 'S', 'CH', 'PT', 'TN' ];
    if( $.inArray($('#pays').val(), country_4_digits ) >= 0 ) {
        nb = 4;
    }

    var flag = verif_num( elementId, 'login_cp', nb );
    if( flag ) flag = verif_zip( elementId, 'pays', 'login_cp', nb );

    try {
        if ($("#clientPays").length > 0) {
            var clientPays = $("#clientPays").val();
        } else {
            var clientPays = $("#pays").val();
        }
        
        if ( (clientPays == 'United Kingdom' || clientPays == 'UK' || clientPays == 'GB') && this.checkAlpha('codeP', 'login_cp')) 
            flag = true;
        
    } catch (e) {}

    if (! flag) {
        this.showError (elementId, $("#erreur_msg").html());
        return false;
    } else {
        this.clearError(elementId);
        return true;
    }
    return false;
};

WPApi.prototype.checkPostalCodeInter = function (elementId, elementIdPays) {

    if(typeof elementIdPays != "undefined" && elementIdPays != null){

        var elem = $('#'+elementIdPays);
        var code_pays = elem.val();
        var response = ajax_file(path_relative_root+'ajax_regex_cp_par_pays.php?code_pays='+code_pays);

        if(response === ''){

            return this.checkPostalCode(elementId);

        } else if(response === 0){

            this.clearError(elementId);
            return true;

        } else {

            var pattern = response.trim();

            var unicodeWord = XRegExp(pattern);
            var isOk = unicodeWord.test($('#' + elementId).val());

            if (isOk) {

                this.clearError(elementId);
                return true;

            } else {

                var msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=login_cp');
                this.showError(elementId, msg);
                return false;

            }

        }
    }

    return this.checkPostalCode(elementId);
};

WPApi.prototype.checkSelectPays = function (elementId) {

    var elem = $("#" + elementId);
    var flag = true;
    var elemVal = elem.val();

    if(elemVal == undefined || elemVal.length <= 0){
        flag = false;
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=login_pays');
    }

    if(!flag){
        this.showError(elementId, msg);
        return false;
    }else{
        this.clearError(elementId);
        return true;
    }
};

WPApi.prototype.checkGoogleAddr = function(elementId, targetVerif ){

    var elem = $("#" + elementId);
    var $targetVerif = $("#" + targetVerif);
    var elemDataGoogle = elem.data('google-val');
    var isBypass = elem.data('bypass');
    var flag = true;
    var array_code_valide = ['B', 'L', 'MO', 'S', 'FR', 'DO', 'TO', 'PT'];

    if(targetVerif == 'pays'){
        if(elemDataGoogle == undefined){
            flag = false;
            msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_adresse');
        }else if(elemDataGoogle.length > 0 && (($targetVerif.prop('type') === 'select-one' && $('option[data-name='+elemDataGoogle+']',$targetVerif).length == 0) || ($targetVerif.prop('type') === 'hidden' && $targetVerif.val() === ''))){
            flag = false;
            msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_country');
        }
    }
    if(targetVerif == 'zip'){
        var splitDataGoogle = elemDataGoogle.split('|');
        var dataPays = splitDataGoogle[0];
        var dataZip = splitDataGoogle[1];
        if( dataPays == undefined || dataPays == '' || $.inArray(dataPays, array_code_valide) == -1){
            flag = false;
            msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_country');
        }else if( dataZip == undefined || $.isNumeric(dataZip) == false || dataZip.length < 2){
            flag = false;
            msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_postalCode_map');
        }
    }
    if(isBypass != undefined && isBypass == true && flag == false){
        flag = true;
    }
    if(!flag){
        this.showError(elementId, msg);
        return false;
    }else{
        this.clearError(elementId);
        return true;
    }
};

/*
 * check parrainage field
 * @params
 *      String elementId - id of the elemement
 *          that will be verified
*/
WPApi.prototype.checkParrainage = function(elementId) {
    this.hideTooltip();
    if( $("#" + elementId).val() == '') {
        return true;
    }
    flag = verif_alphanum(elementId,"login_code_parrainage",false);

    if(flag) {
        flag = ajax_file('ajax_valid_code_parrain.php?email=' + $('#clientMail').val()+'&code=' + $("#" + elementId).val());
        if( flag == '1' || flag == '2' ) {
            var txt = ( flag == '1' )? 'js_erreur_parrain_1':'js_erreur_parrain_2';
            var errorMessage = ajax_file(path_relative_root+'ajax_sprintf.php?arg1='+txt);
            this.showError (elementId, errorMessage);
            return false;
        } else {
            return true;
        }
    }
    return flag;
};

/*
 * unbind all events for elementId and remove all inline html attributes
 * the events will be assigned from javascript
 * @params
 *      String elementId - id of the elemement
 *          that will be modified
*/
WPApi.prototype.unbindAll = function(elementId) {

    var elm = $("#" + elementId);
    var is_intltel;
    var country;

    if (elm.length > 0) {

        is_intltel = elm.parent().hasClass("intl-tel-input");

        if (is_intltel) {

            country = elm.intlTelInput("getSelectedCountryData");

            elm.intlTelInput("destroy");
        }

        elm.off();
        elm.removeAttr("onblur");
        elm.removeAttr("onfocus");
        elm.removeAttr("onclick");
        elm.removeAttr("onmouseover");
        elm.removeAttr("onmouseout");

        if (is_intltel) {

            elm.intlTelInput({
                utilsScript: path_relative_root + 'js/intltelinput/utils.js',
                initialCountry: country.iso2
            });
        }
    }
};

/*
 * unbind all events for elementId and remove all inline html attributes
 * the events will be assigned from javascript
 * @params
 *      String elementId - id of the elemement
 *      String formId - id of the form
 *          that will be modified
*/
$.fn.submitEnter = function() {
    var $target = $(this);
    var $form = $target.closest('form');
    $(function(){
        $('input[type=text], input[type=password]',$form).on('keydown',function(e){
            if(e.keyCode == 13){
                $target.trigger('click');
            }
        });
    });
    return this;
};

/*
 * submit a form for verification by ajax
 * @params
 *      String formId - id of the form
 *      String triggerId - id of the element that fire up the submit event
 *      String loaderId - id of the loader that will be displayed
*/
WPApi.prototype.submitForm = function (formId, triggerId, loaderId) {
    if (typeof loaderId == "undefined") {
        loaderId = "loader_1";
    }
    var that = this;
    $("#" + triggerId).hide();
    $("#" + loaderId).show();
    var frm =  $("#" + formId);
    frm.trigger('submitFormEvent');
    var frm_action = frm.attr('action');
    if (frm_action == '') {
        frm_action = document.location.href;
        frm.attr('action', frm_action);
    }
    var frm_method = frm.attr('method');
    if(frm_method == ''){
        frm_method = 'POST';
    }

    $.ajax({
        type: frm_method,
        url: frm_action,
        data: frm.serialize(),
        success: function (data) {
            if (data.error == 1) {
                var j = 0;
                for (var i in data.result) {
                    if(i == 'pays'){
                        data.result[i] = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=login_pays');
                    }
                    that.showError(i, data.result[i]);
                    j++;
                }
                $("#" + triggerId).show();
                $("#loader_1").hide();
                $(".info_error").slideDown();
            } else {
                $(".info_error").slideUp();
                try {
                    customSubmitForm(data);
                } catch (e) {
                    frm.submit();
                }
            }
        },
        error: function () {
            $("#" + triggerId).show();
            $("#" + loaderId).hide();
        }
    }).always(function(){
        frm.trigger('endSubmitFormEvent');
    });
};

//Builds an URL based on the variable path_relative_root or the current page URL elements
WPApi.prototype.buildURL = function(file) {
    if (file.charAt(0) === '/') {
        file = file.substr(1);
    }
    if (typeof path_relative_root != 'undefined') {
        return window.location.origin+path_relative_root+file;
    } else {
        pathParts = window.location.pathname.split('/');
        selectedPathParts = new Array();
        for (var i in pathParts) {
            if (parseInt(i)+1 < pathParts.length) {
                selectedPathParts.push(pathParts[i]);
            }
        }
        return window.location.origin+selectedPathParts.join('/')+'/'+file;
    }
};

/**
 * Gets a localized message from the server
 *
 * @event   messageAvailable Emitted when the server returned the desired message
 * @param  {string} ID     Message ID as seen in xx.po
 * @param  {array} params  Sprintf parameters corresponding to the message
 * @return {void}          Nothing
 */
WPApi.prototype.fetchMessage = function(ID, params) {
    var that = this;
    serviceURL = this.buildURL('ajax_sprintf.php');
    requestData = {'arg1': ID};

    if (params) {
        for (var i = 0; i < params.length;i++) {
            requestData['arg'+(parseInt(i)+2)] = params[i];
        }
    };

    $.get(serviceURL, requestData, function(response) {
        that.emit('messageAvailable', response);
    });
};

/*
* End custom events system
*/

/**
 * changeVisuals Image src changing method with preloading, error handling, graceful fallback image and promises
 *
 * @param visualsOptions Object in which properties names are jQuery selectors for the images
 * and those properties values are the new images URL
 * Example:
 *
 * {
 *  'jQuery selector for image 1' : 'pathToNewVisual',
 *  'jQuery selector for image 2' : 'pathToNewVisual2'
 * }
 *
 * @param defaultImg Default image to load in case of error (404)
 *
 * @return jQuery Deferred A jQuery promise
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
WPApi.prototype.changeVisuals = function(visualsOptions, defaultImg) {
    var mainPromise = jQuery.Deferred();//Will return a promise
    var promisesPoolResultCount = 0;//Will resolve that promise when all sub promises are resolved

    mainPromise.pool = [];//Pool of subpromises

    for (var i in visualsOptions) {
        if ($(i).length > 0) {
            var subPromise = jQuery.Deferred();//One subpromise by image
            var preloader = $('<img style="position:absolute;left:-5000px;top:-5000px;" />');

            mainPromise.pool.push(subPromise);//Add subpromise to the pool
            subPromise.always(function() {
                promisesPoolResultCount++;
            });//When the subpromise is either resolved or rejected increment the ended promises count
            //$(i).fadeOut(300);//Fade image tag out
            preloader.on('load',//When new image is loaded in preloader
                (function(element, visual, promise) {
                    $(element).attr('src', this.src);//Then change target tag src
                    /*setTimeout(function(){
                        $(element).fadeIn(200);//And fade it in
                    }, 200);*/
                    $(this).remove();//Then remove preloader tag
                    promise.resolve(element, visual);//Finally resolve the promise for that image load
                }).bind(preloader.get(0), i, visualsOptions[i], subPromise)//Bind context to load event callback
            ).on('error', (function(element, defaultImg, promise) {//When image couldn't load
                $(this).remove();//Remove preloader from the DOM
                if (typeof defaultImg != 'undefined' && defaultImg.length > 0) {//If a fallback image has been specified
                    var $WPApi = new WPApi();
                    var visuals = {};
                    visuals[element] = defaultImg;
                    promise.reject(element, visuals);//Reject promise
                    return $WPApi.changeVisuals(visuals);//Then load fallback image
                }
            }).bind(preloader.get(0), i,  defaultImg, subPromise));
            $('body').prepend(preloader);
            preloader.attr('src', visualsOptions[i]);
        }
    }
    //Every 20ms check if the number of resolved/rejected promises in the pool equals the total of promises in the pool
    var promisePoolPoll = setInterval(function() {
        if (promisesPoolResultCount == mainPromise.pool.length) {//When the total number of resolved/rejected sub promsies equals the pool count
            mainPromise.resolve(visualsOptions);//Then all images are loaded, resolve main promise
            clearInterval(promisePoolPoll);//Stop polling
        }
    }, 20);
    return mainPromise.promise();
};

/*
 * inherited class ConnectionPage
 * extend abstract object WPApi
 * process forms from connection.php page
*/
function ConnectionPage() {
  // Call the parent constructor
  WPApi.call(this);
}
ConnectionPage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
ConnectionPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("clientNom");
    this.unbindAll("clientPrenom");
    this.unbindAll("clientPseudo");
    this.unbindAll("clientMail");
    this.unbindAll("clientMailv");
    this.unbindAll("clientMailAuth");
    this.unbindAll("clientPwd");
    this.unbindAll("clientPwdv");
    this.unbindAll("clientPwdAuth");
    this.unbindAll("clientVille");
    this.unbindAll("codeParrainage");
    this.unbindAll("auth");
    this.unbindAll("clientForm");
    this.unbindAll("bloc_erreur");
    this.unbindAll("btn-pwdlost");
    this.unbindAll("btn_creer_compte");
    this.unbindAll("clientPwdAuthErr");
    this.unbindAll("clientCompany");
    this.unbindAll("clientVat");
    this.unbindAll("clientTaxNbr");
    this.unbindAll("clientTel");
    this.unbindAll("clientPays");
    this.unbindAll("codeP");
};

/*
 * overrided function addListeners
 * add listeners for all fields in this object
*/
ConnectionPage.prototype.addListeners = function() {
    this.addListenersLoginForm();
    this.addListenersRegisterForm();
    this.addListenersLostPasswordForm();
    this.addListenersContactForm();
};

/*
 * add listeners for all login form fields
*/
ConnectionPage.prototype.addListenersLoginForm = function() {
    var that = this;
    /* $("#clientMailAuth").focus(function() {
        that.showTooltip("clientMailAuth", true, $("#clientMailAuthErr").html());
    }); */

    $("#clientMailAuth").click(function() {
        $(this).focus();
    });

    $("#clientPwdAuth").click(function() {
        $(this).focus();
    });

    $("#clientMailAuth").blur(function() {
        that.checkClientEmailAuth();
    });

    $("#clientPwdAuth").blur(function() {
        that.checkClientPasswordAuth();
    });

    $("#AuthErr").click(function() {$("#auth").submit();});
    $("#AuthErr").submitEnter();
    $("#auth").submit(function() {
        elmt = $("#AuthErr");
        loader = $("#loader_2");
        if( elmt.css('display')!='none'){
            prev_display = elmt.css('display');
            elmt.css('display', 'none');
            loader.css('display', 'block');

            if (elmt.closest(':focus').length > 0)
                elmt.closest(':focus').blur();

            if( document.getElementById("garantiePreuveAchat") ) {

                var input = document.getElementById("garantiePreuveAchat");

                // Pour la preuve d'achat car si la taille est > à 4 Mo ça fait tout planter au niveau PHP
                if(input.files && input.files.length == 1)
                {
                    if (input.files[0].size > 4194304) {
                        $("#erreur_preuve_achat,#AuthErr").show();
                        $("#loader_2").hide();
                        $("#labelgarantiePreuveAchat").addClass('labelErr');
                        return false;
                    }
                    else {

                        // Cas special uniquement quand on n'est pas connecte
                        if( document.getElementById('identification') )
                            $("#garantie_form").appendTo("#auth");
                        setTimeout(function () {that.checkLoginForm();}, 1000);

                    }
                }
                else {

                    // Cas special uniquement quand on n'est pas connecte
                    if( document.getElementById('identification') )
                        $("#garantie_form").appendTo("#auth");
                    setTimeout(function () {that.checkLoginForm();}, 1000);

                }

            }
            else
                setTimeout(function () {that.checkLoginForm();}, 1000);
        }
    });
};

/*
 * add listeners for all lost password form fields
*/
ConnectionPage.prototype.addListenersLostPasswordForm = function() {
    var that = this;
    /* $("#mailLost").focus(function() {
        that.showTooltip("mailLost", true, $("#clientMailAuthErr").html());
    }); */

    $("#mailLost").click(function() {
        $(this).focus();
    });

    $("#mailLost").blur(function() {
        that.checkClientLostEmail();
    });

    $("#btn-pwdlost").click(function() {
        elmt = $("#btn-pwdlost");
        loader = $("#loader_3");
        if( elmt.css('display') != 'none'){
            prev_display = elmt.css('display');
            elmt.css('display', 'none');
            loader.css('display', 'block');
            setTimeout(function () {that.checkLostPasswordForm();}, 1000);
        }
    });
};

/*
 * add listeners for all register form fields
*/
ConnectionPage.prototype.addListenersRegisterForm = function() {
    var that = this;

    $("#clientNom").blur(function() {
        that.checkLastName('clientNom');
    });

    $("#clientPrenom").blur(function() {
        that.checkFirstName('clientPrenom');
    });

    $("#clientPseudo").blur(function() {
        that.checkNickName('clientPseudo');
    });

    $("#clientMail").blur(function() {
        that.checkClientEmail();
    });
    $("#clientMailv").blur(function() {
        that.compareEmail('clientMailv', 'clientMail');
    });
    $("#clientPwd").blur(function() {
        that.checkClientPassword();
    });
    $("#clientPwdv").blur(function() {
        that.comparePassword('clientPwdv', 'clientPwd');
    });
    $("#codeParrainage").blur(function() {
        that.checkParrainage('codeParrainage');
    });
    $("#clientVille").blur(function () {
        that.checkAlpha('clientVille', 'login_ville', true);
    });
    $("#clientMail").click(function() {
        $(this).focus();
    });
    $("#clientPwd").click(function() {
        $(this).focus();
    });
    $("#clientCompany").blur(function() {
        that.checkAlpha('clientCompany', 'login_company');
    });
    $("#clientVat").blur(function() {
        that.checkVAT('clientVat');
    });
    $("#clientTaxNbr").blur(function() {
        that.checkClientTaxNbr('clientTaxNbr');
    });
    $("#clientTel").blur(function() {
        //that.checkPhoneNumber('clientTel', 'login_tel');
		that.checkPhoneNumber('clientTel', 'login_telephone', false, false);
    });
    $("#codeP").blur(function () {
        that.checkPostalCodeInter('codeP');
    });

    $("#btn_creer_compte").click(function() {$("#clientForm").submit();});
    $("#btn_creer_compte").submitEnter();
    $("#clientForm").submit(function() {
        elmt = $("#btn_creer_compte");
        loader = $("#loader_1");

        if( elmt.css('display') != 'none' ){
            prev_display = elmt.css('display');
            elmt.css('display', 'none');
            loader.css('display', 'block');

            if( document.getElementById("garantiePreuveAchat") ) {

                var input = document.getElementById("garantiePreuveAchat");

                // Pour la preuve d'achat car si la taille est > à 4 Mo ça fait tout planter au niveau PHP
                if(input.files && input.files.length == 1)
                {
                    if (input.files[0].size > 4194304) {
                        $("#erreur_preuve_achat,#btn_creer_compte").show();
                        $("#labelgarantiePreuveAchat").addClass('labelErr');
                        return false;
                    }
                    else {

                        // Cas special uniquement quand on n'est pas connecte
                        if( document.getElementById('identification') )
                            $("#garantie_form").appendTo("#clientForm");
                        setTimeout(function () {that.checkRegisterForm();}, 1000);
                        return false;

                    }
                }
                else {

                    // Cas special uniquement quand on n'est pas connecte
                    if( document.getElementById('identification') )
                        $("#garantie_form").appendTo("#clientForm");
                    setTimeout(function () {that.checkRegisterForm();}, 1000);
                    return false;
                }
            }
            else {
                // Cas special uniquement quand on n'est pas connecte
                if( document.getElementById('identification') )
                    $("#garantie_form").appendTo("#clientForm");
                setTimeout(function () {that.checkRegisterForm();}, 1000);
                return false;
            }
        }
        setTimeout(function(){
            elmt.css('display', 'block');
            loader.css('display', 'none');
        },5000);
    });
};

ConnectionPage.prototype.checkEmailRegister = function (elementId) {
    var that = this;
    this.hideTooltip();
    var action = $("#" + elementId).closest('form').attr('action');
    $.ajax({
        url: path_relative_root + 'ajax_email_exist.php?mail='+ $("#" + elementId).val()+"&err_code=1&action="+action
    }).done(function(data) {
        if (data == 1 || data == 3) {
            var msg = ( data == 1 )? 'js_already_have_account':'login_inactif';
            $.ajax({url:path_relative_root+'ajax_sprintf.php?arg1='+msg})
                    .done(function(data) {
                        $("#clientMailAuth").val($("#" + elementId).val());
                        $('.text','#auth .message_mail_exist').html(that.getMessageWithTitle('clientMailAuth', data)).slideDown();
                    });

            try {
                showBlocForm('is_client', 'from_div');
                that.clearAllErrors();
            } catch (e) {
                that.repportError(e);
            }
            try {
                display_form_is_client();
                that.clearAllErrors();
            } catch (e) {
                that.repportError(e);
            }
        } else if (data != 0) {
            that.showError(elementId, data);
            return false;
        } else {
            that.clearError(elementId);
            return true;
        }
    });
};

ConnectionPage.prototype.checkClientEmail = function() {
    return this.checkEmailRegister("clientMail");
};

ConnectionPage.prototype.checkClientPassword = function() {
    return this.checkPassword("clientPwd");
};

ConnectionPage.prototype.checkClientEmailAuth = function () {
    return this.checkEmail("clientMailAuth");
};

ConnectionPage.prototype.checkClientPasswordAuth = function () {
    return this.checkPassword("clientPwdAuth");
};

ConnectionPage.prototype.checkClientLostEmail = function () {
    return this.checkEmail("mailLost");
};

var allow_submit_create = true;
ConnectionPage.prototype.checkRegisterForm = function() {

    if ( allow_submit_create ) {
         var tabError = new Array();
         tabError[0] = new Array('mail_log','clientMail','login_email');

         if( document.getElementById('clientMail') ){
            var do_submit = checkAllForm( tabError );
         }else{
            var do_submit = true;
         }

         if ( do_submit && document.getElementById('login_mdp') ) {

             do_submit = verif_password('clientPwd', 'login_mdp',false)

         }

         if ( do_submit ) {

             try {
                var href = location.href;
                var regexp = /identification.php/;
                if(regexp.exec(href) != null) {
                    if( typeof(_gaq) != 'undefined' )
                        _gaq.push(['_trackPageview', '/pvcreationcomptebasket.html']);
                    else
                        ga('send', 'pageview', '/pvcreationcomptebasket.html');

                } else{
                    if( typeof(_gaq) != 'undefined' )
                        _gaq.push(['_trackPageview', '/pvcreationcompte.html']);
                    else
                        ga('send', 'pageview', '/pvcreationcompte.html');
                }

            } catch (e) {}

            allow_submit_create = false;
            clientForm.submit();

         } else {
             return false;
         }
    } else {

        return false;

    }
};


ConnectionPage.prototype.checkLoginForm = function() {

    resultat = ajax_file(path_relative_root +'ajax_login_allowed.php?mail='+$("#clientMailAuth").val()+'&mdp='+$("#clientPwdAuth").val()+'&is_top=false');
    res = resultat.split('|');
    if (res[0] == 1) {
        try {

            /****************************************************
            *                       TEDEMIS                     *
            *****************************************************/
            if(res[1] !== "undefined") {
                $('<img src="'+res[1]+'" style="border :0px ;" />').appendTo('body');
            }

            /****************************************************
            * on envoie d'une var 'client' a google analytics *
            *****************************************************/
            // pageTracker._setVar("client");
            if( typeof(_gaq) != 'undefined' )
                _gaq.push(['_setCustomVar', 1, 'user_type', 'client']);
            else
                ga('set', 'user_type', 'client');

        } catch (e) {}

        $("#garantie_form").appendTo("#auth");
        document.getElementById('auth').submit();

        return false;
    } else if(res[0] == 2) {
        $("#AuthInactifErr").show();
        hideLoader();
        return false;
    } else {
        $("#AuthErrErr").show();
        hideLoader();
        return false;
    }
};

ConnectionPage.prototype.checkLostPasswordForm = function() {
    var that = this;
    $.ajax({url: path_relative_root + 'ajax_email_exist.php?mail='+ $("#mailLost").val()+"&err_code=1"}
    ).done(function(data) {
        if (data == 1) {
            $.ajax({url:path_relative_root +'ajax_send_new_password_link.php?mail=' + $("#mailLost").val()})
                    .done(function(data) {
                        $("#lostpwdErr").hide();
                        $("#lostpwdSuccesTitle").hide();
                        $("#lostpwdSucces1").hide();
                        $("#lostpwdSucces2").hide();
                        $("#lostpwdSucces3").show();
                        $("#lostpwdSucces4").show();
                        that.clearError("mailLost");
                        hideLoader();
                    });
        } else if (data != 0) {
            that.showError("mailLost", data);
            hideLoader();
        } else {
            that.showError("mailLost", $("#lostpwdErr").find("td").html());
            hideLoader();
        }
    }).fail(function(){hideLoader();});

};


/*
 * inherited class BankPage
 * extend abstract object WPApi
 * process forms from banque.php page
*/
function BankPage() {
  // Call the parent constructor
  WPApi.call(this);
}
BankPage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
BankPage.prototype.resetAllEvents = function() {    //reset all
    this.unbindAll("cvc_info");
    this.unbindAll("alias_banque_info");
	this.unbindAll("carte_secure");
};

/*
 * overrided function addListeners
 * add listeners for all fields in this object
*/
BankPage.prototype.addListeners = function() {
    var that = this;
    $("#cvc_info").mouseover(function () {
        that.showTooltip("cvc_info", false, $("#infos_carte").html(), 0);
    });
    $("#cvc_info").mouseout(function () {
        that.timerTooltip = setTimeout(function() {that.hideTooltip();}, 100);
    });
    $("#alias_banque_info").mouseover(function () {
        that.showTooltip("alias_banque_info", false, $("#infos_alias").html());
    });
    $("#alias_banque_info").mouseout(function () {
        that.timerTooltip = setTimeout(function() {that.hideTooltip();}, 100);
    });
	 $("#carte_secure").mouseover(function () {
        that.showTooltip("carte_secure", false, $("#infos_carte_secure").html());
    });
    $("#carte_secure").mouseout(function () {
        that.timerTooltip = setTimeout(function() {that.hideTooltip();}, 100);
    });
};

/*
 * inherited class MyAccountPage
 * extend abstract object WPApi
 * process forms from mes_infos.php page
*/
function MyAccountPage() {
  // Call the parent constructor
  WPApi.call(this);
}

MyAccountPage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
MyAccountPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("clientMail");
    this.unbindAll("clientNom");
    this.unbindAll("clientPrenom");
    this.unbindAll("clientTel");
    if(typeof $('#clientMobile').val() != 'undefined' ){
        this.unbindAll("clientMobile");
    }
    this.unbindAll("clientPwd");
    this.unbindAll("clientBlog");
};

/*
 * AGN
 *
 * overrided function addListeners
 * add listeners for all fields in this object
*/
MyAccountPage.prototype.addListeners = function() {
    var that = this;
	var checkMobile = true;
	var checkTel = true;

    $("#clientMail").blur(function () {
        that.checkEmail("clientMail", "old_clientMail");
    });

    $("#clientMailv").blur(function () {
        that.compareEmail("clientMailv", "clientMail");
    });

    $("#clientPwd").blur(function () {
        if ($("#clientPwd").val() != '') {
            that.checkPassword("clientPwd");
        }
    });

    $("select#clientDatenaisJ").change(function () {
        that.checkAlpha('clientDatenaisJ', 'login_naissance');
    });
    $("select#clientDatenaisM").change(function () {
        that.checkAlpha('clientDatenaisM', 'login_naissance');
    });
    $("select#clientDatenaisA").change(function () {
        that.checkAlpha('clientDatenaisA', 'login_naissance');
    });

    $("input#clientDatenaisJ, input#clientDatenaisM, input#clientDatenaisA").blur(function () {
        that.checkBirthDate('clientDatenaisJ', 'clientDatenaisM', 'clientDatenaisA', 'login_naissance');
    });

    if( typeof auto_completion_tel !== 'undefined' && auto_completion_tel ){
        $("#clientTel, #clientMobile").blur(function () {
            checkTel = that.checkPhoneNumberAuto("clientTel",'login_telephone', false, false);
            // Si il y a un champs mobile qui existe
            if ( document.getElementById("clientMobile") !== null )
                checkMobile = that.checkPhoneNumberAuto("clientMobile",'login_mobile', true, false);
            else
                checkMobile = false;

            if(checkMobile){
                checkTel = that.checkPhoneNumberAuto("clientTel",'login_telephone', false, true);
            } else if(checkTel && document.getElementById("clientMobile") !== null){
                checkMobile = that.checkPhoneNumberAuto("clientMobile",'login_mobile', true, true);
            }
        });
    } else {

        phone_optional = false;
        if( typeof(phone_mandatory) != 'undefined' ){
            phone_optional = (phone_mandatory == 'false')
        }

        $("#clientTel").blur(function() {
            that.checkPhoneNumber('clientTel', 'login_telephone', false, phone_optional);
        });

        if( document.getElementById("clientMobile") !== null ){
            $("#clientMobile").blur(function() {
                that.checkPhoneNumber('clientMobile', 'login_mobile', true, true);
            });
        }
    }

    $("#clientPwdv").blur(function () {
        that.comparePassword("clientPwdv", "clientPwd");
    });

    $("#codeParrainage").blur(function () {
        that.checkParrainage();
    });

    $("#clientNom").blur(function () {
        that.checkLastName('clientNom');
    });

    $("#clientPrenom").blur(function () {
        that.checkFirstName('clientPrenom');
    });

    $("#clientBlog").blur(function () {
        that.checkUrl('clientBlog', true);
    });

    $("#autocomplete_addr").not('[data-form]').blur(function () {
        that.checkGoogleAddr( $(this).prop('id'), 'pays');
    });

    $("#adresseLiv").blur(function () {
        that.checkAddress($(this).prop('id'));
    });

    $("#codeP").blur(function () {
        that.checkPostalCodeInter('codeP');
    });

    $("#ville").blur(function () {
        that.checkAlpha('ville', 'login_ville', true);
    });

    $("#clientLieunais").blur(function () {
        that.checkAlpha('clientLieunais', 'login_lieu_naissance', true);
    });

    /* FORM HUSBAND/WIFE CUSTOMER */

    $("select#epouxDatenaisJ").change(function () {
        that.checkAlpha('epouxDatenaisJ', 'login_naissance');
    });
    $("select#epouxDatenaisM").change(function () {
        that.checkAlpha('epouxDatenaisM', 'login_naissance');
    });
    $("select#epouxDatenaisA").change(function () {
        that.checkAlpha('epouxDatenaisA', 'login_naissance');
    });

    $("input#epouxDatenaisJ, input#epouxDatenaisM, input#epouxDatenaisA").blur(function () {
        that.checkBirthDate('epouxDatenaisJ', 'epouxDatenaisM', 'epouxDatenaisA', 'login_naissance');
    });

    $("#epouxNom").blur(function () {
        that.checkLastName("epouxNom");
    });

    $("#epouxPrenom").blur(function () {
        that.checkFirstName("epouxPrenom");
    });

    $("#autocomplete_addr_epoux").not('[data-form]').blur(function () {
        that.checkGoogleAddr( $(this).prop('id'), 'epouxPays');
    });

    $("#adresseLivEpoux").blur(function () {
        that.checkAddress($(this).prop('id'));
    });

    $("#epouxcodeP").blur(function () {
        that.checkPostalCodeInter('epouxcodeP');
    });

    $("#epouxVille").blur(function () {
        that.checkAlpha('epouxVille', 'login_ville', true);
    });

    $("#epouxLieunais").blur(function () {
        that.checkAlpha('epouxLieunais', 'login_lieu_naissance', true);
    });

    $("#epouxTel").blur(function() {
        that.checkPhoneNumber('epouxTel', 'login_telephone', false, false);
    });

    $("#btn_infos").click(function () {

        if( typeof auto_completion_tel !== 'undefined' && auto_completion_tel ){
            var checkTel = that.checkPhoneNumberAuto("clientTel",'login_telephone', false, false);
            var checkMobile = false;
            // Si il y a un champs mobile qui existe
            if ( document.getElementById('clientMobile') !== null )
                checkMobile = that.checkPhoneNumberAuto("clientMobile", 'login_mobile',true, false);

            // Si le numero de telephone existe alors le numéro mobile est facultatif
            if( checkTel && document.getElementById('clientMobile') !== null ) {
                checkMobile = that.checkPhoneNumberAuto("clientMobile", 'login_mobile',true, true);
            }

            // SI le mobile est correcte alors le numero de telephone est facultatif
            if( checkMobile ){
                checkTel = that.checkPhoneNumberAuto("clientTel",'login_telephone', false, true);
            }
        }

        that.submitForm('clientForm', 'btn_infos');
    });
    $("#btn_infos").submitEnter();
};

/*
 * overrided function checkEmail
 * check email address in this specific case
*/
MyAccountPage.prototype.checkEmail = function (elementId, oldelementId) {
    var that = this;
    this.hideTooltip();
    if ($("#" + elementId).val() == $("#" + oldelementId).val()) {
        return true;
    }
    $.ajax({url: path_relative_root + 'ajax_email_exist.php?mail='+ $("#" + elementId).val()+"&err_code=1"})
     .done(function(data) {
        if (data == 1) {
            $.ajax({url:path_relative_root+'ajax_sprintf.php?arg1=js_already_have_account'})
                    .done(function(data) {
                        that.showError(elementId, data);
                        that.showTooltip("iconErr" + elementId, false, that.getMessageWithTitle(elementId, data));
                    });
        } else if (data != 0) {
            that.showError(elementId, data);
            return false;
        } else {
            that.clearError(elementId);
            return true;
        }
    });
};

/*
 * inherited class NewsletterPage
 * extend abstract object WPApi
 * process forms from newsletter.php page
*/
function NewsletterPage() {
  // Call the parent constructor
  WPApi.call(this);
}
NewsletterPage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
NewsletterPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("newsletterMail");
    this.unbindAll("newsletterNom");
    this.unbindAll("newsletterPrenom");
    this.unbindAll("newsletterMailv");
    this.unbindAll("newsletterCompany");
    this.unbindAll("newsletterCp");
    this.unbindAll("newsletterMobile");
};

/*
 * overrided function addListeners
 * add listeners for all fields in this object
*/
NewsletterPage.prototype.addListeners = function() {
    var that = this;
    $("#newsletterMail").blur(function () {
        that.checkEmail("newsletterMail");
    });
    $("#newsletterMailv").blur(function () {
        that.compareEmail("newsletterMailv", "newsletterMail");
    });
    $("#newsletterNom").blur(function () {
        that.checkLastName("newsletterNom");
    });
    $("#newsletterPrenom").blur(function () {
        that.checkFirstName("newsletterPrenom");
    });

    $("#newsletterCompany").blur(function () {
        that.checkAlpha('newsletterCompany', 'login_company');
    });
    $("#newsletterCp").blur(function () {
        that.checkNumber('newsletterCp', 'login_cp');
    });
    $("#newsletterMobile").blur(function () {
        that.checkPhoneNumber('newsletterMobile', 'login_telephone', true, false);
    });

    $("#btn_news").click(function () {
        that.submitForm("newsletterForm", "btn_news");
    });
    $("#btn_news").submitEnter();
};

/*
 * inherited class NewsletterPage
 * extend abstract object WPApi
 * UnsubscribeNewsletterPage is used for all unsubscribe pqges
 * newsletter, Loyalty, Privilege, Catalog, tati-un-an
 */
function UnsubscribeNewsletterPage() {
  // Call the parent constructor
  WPApi.call(this);
}
UnsubscribeNewsletterPage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
UnsubscribeNewsletterPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("newsletterMail");
    this.unbindAll("btn_news_suppr");
};

/*
 * overrided function addListeners
 * add listeners for all fields in this object
*/
UnsubscribeNewsletterPage.prototype.addListeners = function() {
    var that = this;
    $("#newsletterMail").blur(function () {
        that.checkEmail("newsletterMail");
    });

    $("#btn_news_suppr").click(function () {
        that.submitForm("newsletterForm", "btn_news_suppr");
        return false;
    });
    $("#btn_news_suppr").submitEnter();
};

function CartPage() {
  // Call the parent constructor
  WPApi.call(this);
}
CartPage.prototype = Object.create(WPApi.prototype);


/*
 * overrided function addListeners
 * add listeners for the fields "pack_illimite"
*/
CartPage.prototype.addListeners = function() {
    var that = this;
    $("#pack_illimite_accept_cgv_pack").click(function () {
        if(! $('.bloc_pack_illimitee input[name=accept_cgv_pack]')
            .is(':checked')){
                $("#pack_illimite_error_picto").show();
                that.settings.tooltipArrowPosition = 'center';
                errorMessage = $("#pack_illimite_accept_cgv_packErr").html();
                that.showTooltip("accept_cgv_pack", false, errorMessage);
            return false;
        }
        return true;
    });

    $("#accept_cgv_pack").click(function () {
        that.hideTooltip();
        $("#pack_illimite_error_picto").hide();
    });
};

/*
 * inherited class DeliveryPage
 * extend abstract object WPApi
 * process forms from ......php page
*/
function DeliveryPage() {
  // Call the parent constructor
  WPApi.call(this);
}
DeliveryPage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
DeliveryPage.prototype.resetAllEvents = function() {
    //reset all events

    var array_to_check = new Array('');

    // Cas special nouveau tunnel si l'adresse de factu est differente de l'adresse de livraison
    if( $('#billadresseTitre').length > 0 ){
        array_to_check.push('bill');
    }

    var nb_form_to_check = array_to_check.length;

    for( var i=0 ; i<nb_form_to_check ; i++  ) {

        this.unbindAll(array_to_check[i]+"adresseTitre");
        this.unbindAll(array_to_check[i]+"adresseNom");
        this.unbindAll(array_to_check[i]+"adressePrenom");
        this.unbindAll(array_to_check[i]+"societe");
        this.unbindAll(array_to_check[i]+"telephone");
        this.unbindAll(array_to_check[i]+"mobile");
        this.unbindAll(array_to_check[i]+"adresseLiv");
        this.unbindAll(array_to_check[i]+"codeP");
        this.unbindAll(array_to_check[i]+"ville");
        this.unbindAll(array_to_check[i]+"etage");
        this.unbindAll(array_to_check[i]+"ascenseur");
        this.unbindAll(array_to_check[i]+"digicode");

    }

    this.unbindAll("btn_addr_add");
    this.unbindAll("btn_addr_up");

};

/*
 * overrided function addListeners
 * add listeners for all fields in this object
*/
DeliveryPage.prototype.addListeners = function() {

    var that = this;
    var array_to_check = new Array('');
    var init_tel = 0;

    // Cas special nouveau tunnel si l'adresse de factu est differente de l'adresse de livraison
    if( $('#billadresseTitre').length > 0  ){
        array_to_check.push('bill');
    }

    var nb_form_to_check = array_to_check.length;

    for( var i=0 ; i<nb_form_to_check ; i++  ) {

        $("#"+array_to_check[i]+"adresseTitre").blur(function () {
            that.checkAlpha($(this).prop('id'), 'title_address');
        });

        $("#"+array_to_check[i]+"adresseCiviliteM, #"+array_to_check[i]+"adresseCiviliteMlle, #"+array_to_check[i]+"adresseCiviliteMme, #"+array_to_check[i]+"inputadresseCiviliteM, #"+array_to_check[i]+"inputadresseCiviliteMlle, #"+array_to_check[i]+"inputadresseCiviliteMme").click(function () {
            that.clearError('adresseCivilite');
        });

        $("select#clientDatenaisJ").change(function () {
            that.checkAlpha('clientDatenaisJ', 'login_naissance');
        });
        $("select#clientDatenaisM").change(function () {
            that.checkAlpha('clientDatenaisM', 'login_naissance');
        });
        $("select#clientDatenaisA").change(function () {
            that.checkAlpha('clientDatenaisA', 'login_naissance');
        });

        $("#"+array_to_check[i]+"adresseNom").blur(function () {
            that.checkLastName($(this).prop('id'));
        });
        $("#"+array_to_check[i]+"adressePrenom").blur(function () {
            that.checkFirstName($(this).prop('id'));
        });
        $("#"+array_to_check[i]+"societe").blur(function () {
            if ($(this).val() != '') {
                that.checkAlpha($(this).prop('id'), 'js_ce_societe');
            } else {
                that.clearError($(this).prop('id'));
            }
        });

        $("input#clientDatenaisJ, input#clientDatenaisM, input#clientDatenaisA").blur(function () {
            that.checkBirthDate('clientDatenaisJ', 'clientDatenaisM', 'clientDatenaisA', 'login_naissance');
        });

        if ( typeof auto_completion_tel != 'undefined' && auto_completion_tel ) {
            $("#"+array_to_check[i]+"telephone").blur(function () {
                if(init_tel > 0){
                    that.checkPhoneNumberAuto($(this).prop('id'),'login_telephone', false);
                }
                init_tel++;
            });
            if( document.getElementById(array_to_check[i]+'mobile') !== null ){

                $("#"+array_to_check[i]+"mobile").blur(function () {
                    if (this.value !== '') {
                        that.checkPhoneNumberAuto($(this).prop('id'), 'login_mobile',true);
                    } else {
                        that.clearError($(this).prop('id'));
                    }
                });

            }
        } else{

            $("#"+array_to_check[i]+"telephone").blur(function () {
                that.checkPhoneNumber($(this).prop('id'),'login_telephone', false, false);
            });

            $("#"+array_to_check[i]+"mobile").blur(function () {
                if (this.value !== '') {
                    that.checkPhoneNumber($(this).prop('id'), 'login_mobile',true);
                } else {
                    that.clearError($(this).prop('id'));
                }
            });

        }

        $("#"+array_to_check[i]+"adresseLiv").blur(function () {
            that.checkAddress($(this).prop('id'));
        });

        $("#"+array_to_check[i]+"codeP").blur(function () {
            var pays_id = typeof array_to_check[i] === 'undefined' ? 'pays' : array_to_check[i]+'pays';
            that.checkPostalCodeInter($(this).prop('id'), pays_id);
        });

        $("#"+array_to_check[i]+"ville").blur(function () {
            that.checkAlpha($(this).prop('id'), 'login_ville', true);
        });

        $("select#"+array_to_check[i]+"pays").change(function () {
            if($(this).val() != '' && $(this).val() != undefined){
                $(this).data('google-val',$(this).val());
            }
            that.checkSelectPays($(this).prop('id'));

            var codeP_id = typeof array_to_check[i] === 'undefined' ? 'codeP' : array_to_check[i]+'codeP';

            that.checkPostalCodeInter(codeP_id, $(this).prop('id'));
        });
        if( typeof $("#"+array_to_check[i]+"autocomplete_addr").val() != 'undefined' ){
            $("#"+array_to_check[i]+"autocomplete_addr").not('[data-form]').on('place_changed blur',function () {
                that.checkGoogleAddr( $(this).prop('id'), 'pays');
            });
        }

        $("#"+array_to_check[i]+"etage").blur(function () {
            if ($(this).val() != '') {
                that.checkAlpha($(this).prop('id'), 'login_etage|3');
            } else {
                that.clearError($(this).prop('id'));
            }
        });
       $("#"+array_to_check[i]+"ascenseur").blur(function () {
            if ($(this).val() != '') {
                that.checkAlpha($(this).prop('id'), 'login_ascenseur|5');
            } else {
                that.clearError($(this).prop('id'));
            }
        });
        $("#"+array_to_check[i]+"digicode").blur(function () {
            if ($(this).val() != '') {
                that.checkAlpha($(this).prop('id'), 'login_digicode|5');
            } else {
                that.clearError($(this).prop('id'));
            }
        });


    }

    $("#btn_addr_up, #btn_addr_add").click(function () {

        if( typeof auto_completion_tel != 'undefined' && auto_completion_tel ){
            var checkTel = that.checkPhoneNumberAuto("telephone",'login_telephone', false);
            if(!checkTel){
                if( document.getElementById("telephoneHidden") && document.getElementById("telephoneHidden").value != "" ){
                    document.getElementById("telephoneHidden").value = '';
                }
                if( document.getElementById("mobileHidden") && document.getElementById("mobileHidden").value != ""){
                    document.getElementById("mobileHidden").value = '';
                }
            }
            if( document.getElementById("mobile") && document.getElementById("mobile").value !== "" ){
                var checkMobile = that.checkPhoneNumberAuto("mobile", 'login_mobile',true);
                if(!checkMobile){
                    document.getElementById("telephoneHidden").value = '';
                    document.getElementById("mobileHidden").value = 'undefined';
                }
            }
        }

    });

    $("#btn_addr_add").click(function () {

        $('#id_transp').attr('value', $('.choix_delivery.activate').data('id-transporteur'));

        that.submitForm("adresseForm", "btn_addr_add");

        return false;
    });

    $("#btn_addr_add").submitEnter();

    $("#btn_addr_up").click(function () {

        that.submitForm("adresseForm", "btn_addr_up");

        return false;
    });

    $("#btn_addr_up").submitEnter();

};

function verif_password(elementID, fieldname,is_spe,label) {

    var flg = 0;
    var alphanum="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');

    if (objElement.value=="")
    {
        if( is_spe == false )
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className += ' inputErr';
        if(objLabel)
            objLabel.className += ' labelErr';

        return false;
    }
    else
    {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <=2)) {
            if( is_spe == false )
                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=2');
            objElement.className += ' inputErr';
            if(objLabel){
                objLabel.className += ' labelErr';
            }
            return false;
        }
        if (objElement.value.length <2 || objElement.value.length > 50) {
            if( is_spe == false )
                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=' + fieldname );
            objElement.className = objElement.className.replace( 'inputForm', '' );
            objElement.className += ' inputErr';
            if(objLabel){
                objLabel.className += ' labelErr';
            }
            return false;
        }
        for (var k=0;k<objElement.value.length;k++){
            var test = objElement.value.substring(k,k+1);
            if (alphanum.indexOf(test)<0)
                flg++;
        }
        if (flg>0) {
            if( is_spe == false )
                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + fieldname);
            objElement.className += ' inputErr';
            if(objLabel){
                objLabel.className += ' labelErr';
            }
            flg=0;
            return false;
        }
        if( is_spe == true && objElement.value == label ) {
            objElement.className += ' inputErr';
            return false;
        }
    }

    objElement.className = objElement.className.replace( 'inputErr', '' );
    objElement.className += ' inputForm';
    if(objLabel){
        objLabel.className = objLabel.className.replace( 'labelErr', '' );
        objLabel.className += ' labelForm';
    }
    return true;
}

function CustomGlassPage() {
    var that = this;
    WPApi.call(that);
}
CustomGlassPage.prototype = Object.create(WPApi.prototype);

CustomGlassPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("customGlassNom");
    this.unbindAll("customGlassPrenom");
    this.unbindAll("customGlassTel");
    this.unbindAll("customGlassTelRegion");
    this.unbindAll("customGlassSociete");
    this.unbindAll("customGlassSubmit");
};

CustomGlassPage.prototype.addListeners = function() {
    var that = this;
    var form = $('form#customGlassForm');
    form.on({
        'blur':function(){
            that.checkLastName('customGlassNom');
        }
    },"#customGlassNom").on({
        'blur':function(){
            that.checkFirstName('customGlassPrenom');
        }
    },"#customGlassPrenom").on({
        'blur':function(){
            that.checkSociete('customGlassSociete');
        }
    },"#customGlassSociete").on({
        'blur':function(){
            that.checkNumber('customGlassTelRegion', 'login_indicatif');
        }
    },"#customGlassTelRegion").on({
        'blur':function(){
            that.checkNumber('customGlassTel', 'login_telephone_custom');
        }
    },"#customGlassTel");

    $("#customGlassSubmit").click(function () {
        that.submitForm("customGlassForm", "customGlassSubmit");
        return false;
    });

    $("#customGlassSubmit").submitEnter();
};

CustomGlassPage.prototype.checkSociete = function(elementId) {
    return this.checkAlphaSpeChar(elementId, "login_societe");
};

function ContactProPage() {
    var that = this;
    WPApi.call(that);
}
ContactProPage.prototype = Object.create(WPApi.prototype);

ContactProPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("contactProPoste");
    this.unbindAll("contactProObjet");
    this.unbindAll("contactProNom");
    this.unbindAll("contactProPrenom");
    this.unbindAll("contactProMail");
    this.unbindAll("contactProTel");
    this.unbindAll("contactProCompagnie");
    this.unbindAll("contactProMessage");
    this.unbindAll("contactProSubmit");
};

ContactProPage.prototype.addListeners = function() {
    var that = this;
    var form = $('form#contactProForm');
    form.on({
        'blur':function(){
            that.checkPoste('contactProPoste');
        }
    },"#contactProPoste").on({
        'blur':function(){
            that.checkObjet('contactProObjet');
        }
    },"#contactProObjet").on({
        'blur':function(){
            that.checkLastName('contactProNom');
        }
    },"#contactProNom").on({
        'blur':function(){
            that.checkFirstName('contactProPrenom');
        }
    },"#contactProPrenom").on({
        'blur':function(){
            that.checkEmail('contactProMail');
        }
    },"#contactProMail").on({
        'blur':function(){
            that.checkNumber('contactProTel', 'login_telephone', false, true);
        }
    },"#contactProTel").on({
        'blur':function(){
            that.checkCompagnie('contactProCompagnie');
        }
    },"#contactProCompagnie").on({
        'blur':function(){
            that.checkTextarea('contactProMessage');
        }
    },"#contactProMessage");

    $("#contactProSubmit").click(function () {
        that.submitForm("contactProForm", "contactProSubmit");
        return false;
    });

    $("#contactProSubmit").submitEnter();
};

ContactProPage.prototype.checkCompagnie = function(elementId) {
    return this.checkAlphaSpeChar(elementId, "login_compagnie");
};

ContactProPage.prototype.checkObjet = function(elementId) {
    return this.checkAlphaSpeChar(elementId, "login_objet");
};

ContactProPage.prototype.checkPoste = function(elementId) {
    var elem = $("#" + elementId);
    var flag = true;
    var elemVal = elem.val();

    if(elemVal == undefined || elemVal.length <= 0){
        flag = false;
        msg = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_thanks_precise&arg2=login_poste');
    }

    if(!flag){
        this.showError(elementId, msg);
        return false;
    }else{
        this.clearError(elementId);
        return true;
    }
};

function CallBackPage() {
  // Call the parent constructor
  var that = this;
  WPApi.call(that);
}
CallBackPage.prototype = Object.create(WPApi.prototype);

CallBackPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("callBackIndicatif");
    this.unbindAll("callBackTel");
    this.unbindAll("callBackSociete");
    this.unbindAll("callBackSubmit");
};

CallBackPage.prototype.addListeners = function() {
    var that = this;
    var form = $('form#callBackForm');
    form.on({
        'blur':function(){
            that.checkNumber('callBackIndicatif', 'login_indicatif', false, false);
        }
    },"#callBackIndicatif").on({
        'blur':function(){
            that.checkNumber('callBackTel', 'login_telephone_custom', false, false);
        }
    },"#callBackTel").on({
        'blur':function(){
            that.checkSociete('callBackSociete');
        }
    },"#callBackSociete");

    $("#customGlassSubmit").click(function () {
        that.submitForm("callBackForm", "callBackSubmit");
        return false;
    });

    $("#callBackSubmit").submitEnter();
};

CallBackPage.prototype.checkSociete = function(elementId) {
    return this.checkAlphaSpeChar(elementId, "login_societe");
};

function ContactPage() {
  // Call the parent constructor
  var that = this;
  WPApi.call(that);
}
ContactPage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
ContactPage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("contactNom");
    this.unbindAll("contactPrenom");
    this.unbindAll("contactMail");
    this.unbindAll("contactRefProduit");
    this.unbindAll("contactMessage");
    this.unbindAll("btn_contact");
};

ContactPage.prototype.addListeners = function() {
    var that = this;
    var form = $('form#service');
    form.on({
        'blur':function(){
            that.checkLastName('contactNom');
        }
    },"#contactNom").on({
        'blur':function(){
            that.checkFirstName('contactPrenom');
        }
    },"#contactPrenom").on({
        'blur':function(){
            that.checkEmail('contactMail');
        }
    },"#contactMail").on({
        'blur':function(){
            that.checkProductExist('contactRefProduit');
        }
    },"#contactRefProduit").on({
        'blur':function(){
            that.checkTextarea('contactMessage');
        }
    },"#contactMessage");

    $("#btn_contact").click(function () {
        that.submitForm("service", "btn_contact");
        return false;
    });

    $("#btn_contact").submitEnter();
};

ContactPage.prototype.checkProductExist = function(elementId) {
    var $elem = $('#'+elementId);
    var elemVal = $elem.val();
    var responce = ajax_file('ajax_verif_contact.php?refProduit='+elemVal);
    responce = $.parseJSON(responce);
    var result = responce[0];
    var msg = responce[1];
    var flag = true;

    if(result == 'false'){
        flag = false;
    }

    if(!flag){
        this.showError(elementId, msg);
        return false;
    }else{
        this.clearError(elementId);
        return true;
    }
};


function passwordThanks() {
  // Call the parent constructor
  var that = this;
  WPApi.call(that);
}

passwordThanks.prototype = Object.create(WPApi.prototype);
/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/

passwordThanks.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("clientPwd");
    this.unbindAll("clientPwdv");
    this.unbindAll("btn_pwd");
};

passwordThanks.prototype.addListeners = function() {
    var that = this;
    var form = $('form#clientForm');
    form.on({
        'blur':function(){
            that.checkPassword('clientPwd');
        }
    },"#clientPwd").on({
        'blur':function(){
            that.comparePassword('clientPwdv','clientPwd');
        }
    },"#clientPwdv");
    $("#btn_pwd").on({
        'click':function(){
            that.submitForm("clientForm", "btn_pwd");
            return false;
        }
    });
    $("#btn_pwd").submitEnter();
};

function deliveryChoice() {
  // Call the parent constructor
  var that = this;
  WPApi.call(that);
}

deliveryChoice.prototype = Object.create(WPApi.prototype);
/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/

deliveryChoice.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("autocomplete_addr_map");
};

deliveryChoice.prototype.addListeners = function() {
    var that = this;
    if(typeof $("#autocomplete_addr_map").val() != 'undefined'){
        $("#autocomplete_addr_map").on('place_changed',function () {
            that.checkGoogleAddr( $(this).prop('id'), 'zip');
        });
    }
};

(function () {
	'use strict';
	function parseArgs(args) {
		var obj = {},
			inc = 0,
			len = args.length;
		for (inc; inc < len; inc += 1) {
			obj['arg' + inc] = args[inc];
		}
		return obj;
	}
	function create_link() {
		var o_data = parseArgs(arguments),
			s_resp = arguments[0];
		$.ajax({
			url : path_relative_root + 'ajax_createlink.php',
			type : 'get',
			data : o_data,
			async : false,
			success : function (res) { s_resp = res }
		});
		return s_resp;
	}
	window.create_link = create_link;
}());

function formParrainage() {
  // Call the parent constructor
  var that = this;
  WPApi.call(that);
}

formParrainage.prototype = Object.create(WPApi.prototype);
/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
formParrainage.prototype.checkEmail = function (elementId) {
    var that = this;
    this.hideTooltip();
    var mailExist = ajax_file(path_relative_root+'ajax_check_parrainage.php?email='+$("#" + elementId ).val());

    if(mailExist != '' && $("#" + elementId ).val() != ''){
        that.showError(elementId, mailExist);
        return false;
    }
    $.ajax({url: path_relative_root + 'ajax_check_email.php?email='+ $("#" + elementId).val()+"&err_code=1"})
     .done(function(data) {
        if (data != 'ok') {
            that.showError(elementId, data);
            that.showTooltip("iconErr" + elementId, false, that.getMessageWithTitle(elementId, data));
            return false;
        } else {
            that.clearError(elementId);
            return true;
        }
    });
};

formParrainage.prototype.resetAllEvents = function() {
    //reset all events
    var that = this;
    $('.bloc_parrain').each(function(i){
        i = i+1;
        that.unbindAll("nomfilleul"+i);
        that.unbindAll("prenomfilleul"+i);
        that.unbindAll("mailfilleul"+i);
    });
    that.unbindAll("envoyer_invitations");
};

formParrainage.prototype.addListeners = function() {
    var that = this;
    var form = $('form#parrainage_form');
    $('form#parrainage_form').find('input[type="text"].inputForm').on('blur', function(e) {
        var ID = $(e.currentTarget).attr('id');
        var regexes = [/^nomfilleul\d/gi, /^prenomfilleul\d/gi, /^mailfilleul\d/gi];
        var functions = [that.checkLastName.bind(that), that.checkFirstName.bind(that), that.checkEmail.bind(that)];
        for (var i = 0;i < regexes.length;i++) {
            if (regexes[i].test(ID)) {
                functions[i](ID);
            }
        }
    });
    $("#envoyer_invitations").click(function () {
        that.submitForm("parrainage_form", "envoyer_invitations");
        return false;
    })
    $("#envoyer_invitations").submitEnter();
};

function formVdi() {
  // Call the parent constructor
  var that = this;
  WPApi.call(that);
}

formVdi.prototype = Object.create(WPApi.prototype);
/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
formVdi.prototype.checkEmail = function (elementId) {
    var that = this;
    this.hideTooltip();

    $.ajax({url: path_relative_root + 'ajax_check_vdi.php?email='+ $("#" + elementId).val()})
     .done(function(mailExist) {
        if(mailExist != '' && $("#" + elementId ).val() != ''){
            that.showError(elementId, mailExist);
            return false;
        }

        $.ajax({url: path_relative_root + 'ajax_check_email.php?email='+ $("#" + elementId).val()+"&err_code=1"})
         .done(function(data) {
            if (data != 'ok') {
                that.showError(elementId, data);
                that.showTooltip("iconErr" + elementId, false, that.getMessageWithTitle(elementId, data));
                return false;
            } else {
                that.clearError(elementId);
                return true;
            }
        });
    });

};

formVdi.prototype.resetAllEvents = function() {
    //reset all events
    var that = this;
    $('.bloc_parrain').each(function(i){
        i = i+1;
        that.unbindAll("mailfilleul"+i);
    });
    that.unbindAll("envoyer_invitations");
};

formVdi.prototype.addListeners = function() {
    var that = this;
    var form = $('form#vdi_form');
    $('.bloc_parrain').each(function(i){
        i = i+1;
        $('#mailfilleul'+i).blur(function () {
            that.checkEmail('mailfilleul'+i);

        });
    });
    $("#envoyer_invitations").click(function () {
        that.submitForm("vdi_form", "envoyer_invitations");
        return false;
    })
    $("#envoyer_invitations").submitEnter();
};

function DemandeCataloguePage() {
  // Call the parent constructor
  WPApi.call(this);
}
DemandeCataloguePage.prototype = Object.create(WPApi.prototype);

/*
 * overrided function resetAllEvents
 * unbind all events for all fields in this object
*/
DemandeCataloguePage.prototype.resetAllEvents = function() {
    //reset all events
    this.unbindAll("catalogueNom");
    this.unbindAll("cataloguePrenom");
    this.unbindAll("catalogueAdresse");
    this.unbindAll("catalogueCp");
    this.unbindAll("catalogueVille");
    this.unbindAll("catalogueTelephone");
    this.unbindAll("catalogueMobile");
    this.unbindAll("catalogueMail");
};

/*
 * overrided function addListeners
 * add listeners for all fields in this object
*/
DemandeCataloguePage.prototype.addListeners = function() {
    var that = this;
    $("#catalogueMail").blur(function () {
        that.checkEmail("catalogueMail");
    });
    $("#catalogueNom").blur(function () {
        that.checkLastName("catalogueNom");
    });
    $("#cataloguePrenom").blur(function () {
        that.checkFirstName("cataloguePrenom");
    });
    $("#catalogueAdresse").blur(function () {
        that.checkAddress('catalogueAdresse', 'login_company');
    });
    $("#catalogueCp").blur(function () {
        that.checkNumber('catalogueCp', 'login_cp');
    });
    $("#catalogueVille").blur(function () {
        that.checkAlpha('catalogueVille', 'login_company');
    });
    $("#catalogueTelephone").blur(function () {

         if( $('#catalogueMobile').val() != '' || $('#catalogueTelephone').val() != '' ) {
            $('.cat_tel').removeClass('inputErr');
            $('#iconErrcatalogueTelephone, #iconErrcatalogueMobile').css('opacity', 0);
        } else {
            $('.cat_tel').addClass('inputErr');
        }

        that.checkPhoneNumber('catalogueTelephone', 'login_telephone', false, false);
    });
    $("#catalogueMobile").blur(function () {

         if( $('#catalogueMobile').val() != '' || $('#catalogueTelephone').val() != '' ) {
            $('.cat_tel').removeClass('inputErr');
            $('#iconErrcatalogueTelephone, #iconErrcatalogueMobile').css('opacity', 0);
        } else {
            $('.cat_tel').addClass('inputErr');
        }

        that.checkPhoneNumber('catalogueMobile', 'login_telephone', true, false);
    });

    $("#btn_demande_catalogue").click(function () {
        if($('#inputcatalogueSexeMr').hasClass('selected') || $('#inputcatalogueSexeMme').hasClass('selected')) {
           $('.txt_civilite').removeClass('inputErr');
        } else {
            $('.txt_civilite').addClass('inputErr');
        }

        if( $('#catalogueMobile').val() != '' || $('#catalogueTelephone').val() != '' ) {
            $('.cat_tel').removeClass('inputErr');
            $('#iconErrcatalogueTelephone, #iconErrcatalogueMobile').css('opacity', 0);
        } else {
            $('.cat_tel').addClass('inputErr');
        }
        that.submitForm("catalogueForm", "btn_demande_catalogue");
    });
    $("#btn_demande_catalogue").submitEnter();
};

function paginationGoToPage(page){

    if( typeof page == 'undefined' ){
        page = 1;
    }
    // On cache la pagination du bas le temps que tous les produits soit charger pour eviter que la pagination du bas remonte entre temps
    if ( $('#pagination_content_bis').length > 0 )
        $('#pagination_content_bis').css('display', 'none');

    var type = document.getElementById( 'type_tri' ).value;
    var idObj = document.getElementById( 'id_page' ).value;

    var page_bloc = parseInt(page-1);

    var content_page = '';

    if( from_type == 'product' ){
        content_page = generateNewBlocProd( type, idObj, path_relative_root, page_bloc );
    } else {
        var query_string = document.getElementById( 'query_string' ).value;
        var nb_product_by_page = document.getElementById( 'nb_prod' ).value;
        content_page = generateNewBlocSearch(query_string,page_bloc, path_relative_root, nb_product_by_page);
    }

    $('#list_item').html(content_page);
    var nb_produits  = document.getElementById( 'title_nb_articles' ).innerHTML;
     nb_produits = nb_produits.replace(/[()]/g, ' ');
     nb_produits = nb_produits.replace(" ", "");

    var nb_total_page = Math.ceil( parseInt(nb_produits) / document.getElementById( 'nb_prod' ).value);

    $.ajax({
        url : path_relative_root + 'ajax_reload_pagination.php',
        data:{page: page , link : 'paginationGoToPage()', nb_total_page : nb_total_page},
        datatype : 'html',
        type: "GET",
        success: function(pagination){
            if($('#pagination_content').hasClass('pagination_content')){
                $('.pagination_content').html(pagination);
                // Si pagination_content_bis existe alors on l'affiche
                if ( $('#pagination_content_bis').length > 0 )
                    $('#pagination_content_bis').css('display', 'block');
            } else {
                $('#pagination_content').html(pagination);

            }
        }
    });


   window.scrollTo(0, 0);

}

function refreshPagination(){
    var page = 1;
    var nb_total_page = Math.ceil( parseInt(document.getElementById( 'title_nb_articles' ).innerHTML) / document.getElementById( 'nb_prod' ).value);

     $.ajax({
        url : path_relative_root + 'ajax_reload_pagination.php',
        data:{page: page , link : 'paginationGoToPage()', nb_total_page : nb_total_page},
        success: function(pagination){
            if($('#pagination_content').hasClass('pagination_content')){
                $('.pagination_content').html(pagination);
                // Si pagination_content_bis existe alors on l'affiche
                if ( $('#pagination_content_bis').length > 0 )
                    $('#pagination_content_bis').css('display', 'block');
            } else {
                $('#pagination_content').html(pagination);

            }
        }
    });
}

/**
 *
 * @author Sébastien Vray sv@webpopulation.com
 *
 * This class handles Facebook and Twitter signup and login features
 *
 * @param buttons CSS selectors of the social buttons onto listen click events and do appropriate stuff after that to log people in
 *
 * Example :
 *
 * {
 *      FBConnectButton: ".my_fb_connect_button"
 *      TTConnectButton: ".my_tt_connect_button"
 * }
 *
 * @event authRequest fired when an authentication request is sent to a social network API
 * @event FBLoginResponse fired when a response is available via the Facebook SDK, response is accessible through data property
 * @event TTConnectResponse fired after the user gave (or not) credentials to the app to access his data, response is accessible through data property
 * @event connected fired when the user has been authenticated by the server
 * @event connectFailure fired when the server failed to authenticate user through his social account
 * @event appError fired at errors at WShop execution
 *
 */

function    SocialConnector(buttons) {
    var     that = this;

    this.ajaxSocialConnectURL = this.buildURL('ajax_social_connect.php');

    //Facebook login & signup logic
    if (typeof buttons != 'undefined' && typeof buttons.FBConnectButton != 'undefined' &&
        $(buttons.FBConnectButton).length > 0) {
            this.addEventListener('FBLoginResponse', this._FBLoginCallback);
            $(buttons.FBConnectButton).click(function(e) {
                that.emit('authRequest');
                e.preventDefault();
                FB.login( function(response){
                    that.emit('FBLoginResponse', response);
                }, {scope: 'email,user_birthday'});
            });
    }

    //Twitter login & signup logic
    if (typeof buttons != 'undefined' && typeof buttons.TTConnectButton != 'undefined' &&
        $(buttons.TTConnectButton).length > 0) {
            $(buttons.TTConnectButton).on('click', this._TTGetOAuthTkn.bind(this));
    }
};

SocialConnector.prototype = Object.create(WPApi.prototype);

SocialConnector.prototype._FBLoginCallback = function(e) {
    var     that = this;
    if (e.status == 'connected' && e.authResponse && e.authResponse.accessToken) {
        $.ajax({
            url : this.ajaxSocialConnectURL,
            type: 'post',
            data: { type: 'facebook', accessToken : e.authResponse.accessToken},
            headers: {accept: 'application/json'},
            success : function(response){
                response = JSON.parse(response);
                if (response && response.success) {
                    if (window.ga != undefined) {
                        ga('send','event','Facebook Connexion','Nouveau Client');
                    };
                    that.emit('connected', response);
                } else if (response && !response.success && response.result == -1) {
                    that.emit('appError', response);
                } else {
                    that.emit('connectFailure', response);
                }
            }
        });
    } else {
        this.fetchMessage('social_signupin_error');
        this.on('messageAvailable', function(e) {
            that.emit('connectFailure', {success: false, result: 0, message: e, signup: false});
        });
    }
};

//Requests Wshop to obtain an OAuth token from Twitter API
SocialConnector.prototype._TTGetOAuthTkn = function(e) {
    var that = this;
    //Open the popup in which will be loaded the authorization
    //page from Twitter in the click handler so it doesn't get blocked by browsers
    var popup = window.open('', '_blank', 'scrollbars=no, height=500, width=500, resizable=no, fullscreen=no');
    var popupWatch;
    var popupQueryString = '';

    this.once('TTConnectResponse', this._TTAuthenticate.bind(this));//When user autorisation request response is available

    e.preventDefault();
    //Ask the application for a callback URL to redirect the user to so he can give proper authorization to our app
    $.ajax({
        url : that.ajaxSocialConnectURL,
        type: 'post',
        data: { type: 'twitter', callbackURL: window.location.origin+window.location.pathname},
        headers: {accept: 'application/json'},
        success : function(response){
            response = JSON.parse(response);
            if (response && response.success) {
                if (response.result == '1') {//If user's session is already opened
                    popup.close();
                    that.emit('connected', response);
                } else {
                    that.emit('authRequest');
                    popup.location = response.result;//Update authorization popup with URL returned by the app

                    //We'll have to periodically watch for windows location changes
                    //because the autorisation request window reload with the callbackurl
                    popupWatch = setInterval(function() {
                        try {
                            if (!popup || popup.closed) {//Test for window closing
                                that._TTOAuthCallback('?denied=closing');//Simulate an autorisation deny
                                clearInterval(popupWatch);
                            }
                        } catch(e) {}
                        try{
                            if (popup.location.origin == window.location.origin &&
                                popup.location.search.length > 0) {//Test for callback URL redirection
                                popupQueryString = popup.location.search;//Backup redirection query string
                                popup.close();//Close autorisation request popup before analysing user response
                                that._TTOAuthCallback(popupQueryString);//Analyze response
                                clearInterval(popupWatch);
                            }
                        } catch(e) {}
                    }, 500);
                }
            } else if (response && !response.success && response.result == -1) {
                that.emit('appError', response);
            }
        }
    });
};

SocialConnector.prototype._TTOAuthCallback = function(callbackQueryString) {
    var eventData = {};

    callbackQueryString = callbackQueryString.substr(1);
    queryStringArgs = callbackQueryString.split('&');
    OAuthVars = {};
    var queryStringArg = [];
    for (var i = 0; i < queryStringArgs.length;i++) {
        queryStringArg = queryStringArgs[i].split('=');
        if (queryStringArg.length == 2 &&
            (queryStringArg[0] === 'oauth_token' || queryStringArg[0] === 'oauth_verifier')) {
            OAuthVars[queryStringArg[0]] = queryStringArg[1];
        } else if (queryStringArg.length == 2 && queryStringArg[0] === 'denied') {
            eventData['status'] = 'denied';
            break;
        }
    }
    if (typeof OAuthVars['oauth_token'] != 'undefined' && typeof OAuthVars['oauth_verifier'] != 'undefined') {
        if (window.ga != undefined) {
            ga('send','event','Twitter Connexion','Nouveau Client');
        }
        eventData['status'] = 'connected';
        eventData['authResponse'] = OAuthVars;
    } else {
        eventData['status'] = 'error';
    }
    this.emit('TTConnectResponse', eventData);
};

SocialConnector.prototype._TTAuthenticate = function(e) {
    var     that = this;
    if (e.status == 'connected' && e.authResponse && e.authResponse.oauth_token) {
        $.ajax({
            url : this.ajaxSocialConnectURL,
            type: 'post',
            data: { type: 'twitter', oauth_token : e.authResponse.oauth_token, oauth_verifier : e.authResponse.oauth_verifier},
            headers: {accept: 'application/json'},
            success : function(response){
                response = JSON.parse(response);
                if (response && response.success) {
                    that.emit('connected', response);
                } else if (response && !response.success && response.result == -1) {
                    that.emit('appError', response);
                } else {
                    that.emit('connectFailure', response);
                }
            }
        });
    } else {
        this.once('messageAvailable', function(e) {
            that.emit('connectFailure', {success: false, result: 0, message: e, signup: false});
        });
        this.fetchMessage('social_signupin_error', []);
    }
};
