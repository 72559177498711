/**
 * Mousemeter
 *
 * Tracks and computes mouse moves
 */
(function () {
    'use strict';

    /**
     * Orphan functions (private)
     *
     * These functions may only be called directly by the meter
     * They cannot be reached from outside the meter at all
     */

    function getScrollTop() {
        if (typeof pageYOffset != 'undefined') {
            //most browsers except IE before #9
            return pageYOffset;
        } else {
            var B = document.body; //IE 'quirks'
            var D = document.documentElement; //IE with doctype
            D = (D.clientHeight)? D: B;
            return D.scrollTop;
        }
    }

    // Computes mouse's distance from last sample
    function computeADist() {
        return Math.sqrt(Math.pow(this.vectr[0], 2) + Math.pow(this.vectr[1], 2));
    }

    // Computes mouse's global direction angle
    function computeAngle() {
        return ((Math.atan2(this.vectr[0], this.vectr[1]) / Math.PI) * 180);
    }

    // Computes mouse's speed
    function computeSpeed() {
        return computeADist.call(this) / (this.ticks[1] - this.ticks[0]);
    }

    // Triggers when mouse enters the browser's window
    // Prevents erroneous data calculation
    function onMouseEnter(e) {
        if (!this.is_over) {
            this.is_over = true;
        }
        this.ticks[0] = new Date().getTime();
        this.ticks[1] = new Date().getTime();
        this.coord = [e.pageX, e.pageY];
        this.vectr = [0, 0];
        this.adist = 0;
        this.angle = 0;
        this.speed = 0;
    }

    // Triggers when mouse leaves the browser's window
    // Prevents erroneous data calculation
    function onMouseLeave(e) {
        if (this.is_over) {
            this.is_over = false;
        }
    }

    // Triggers everytime the mouse moves
    function onMouseMove(e) {
        var offTop = e.pageY - getScrollTop();
        if (this.coord[0] !== null && this.coord[1] !== null) {
            this.vectr = [(e.pageX - this.coord[0]), (offTop - this.coord[1])];
            this.adist = computeADist.call(this);
        }
        this.coord = [e.pageX, offTop];
    }

    /**
     * Constructor
     */
    function Mousemeter() {
        this.is_over = true;
        this.ticks = [0, 0];
        this.coord = [0, 0];
        this.vectr = [0, 0];
        this.adist = 0;
        this.angle = 0;
        this.speed = 0;
        return this.init(new Date().getTime());
    }

    /**
     * Prototype Methods (public)
     *
     * These methods may be called from outside the meter as meter[method]([args])
     */

    // Meter's initiation routine
    // Sets variables to their respective starting value
    // Sets events listeners
    Mousemeter.prototype.init = function (time) {
        var that = this;
        this.ticks[0] = time;
        // Refresh meter's data every 1000/60ms
        window.setInterval(function () {
            that.ticks[1] = new Date().getTime();
            if ((that.vectr[0] !== 0 || that.vectr[1] !== 0) && that.is_over) {
                that.angle = computeAngle.call(that);
                that.speed = Math.round(computeSpeed.call(that) * 1000);
                that.vectr = [0, 0];
            }
            that.ticks[0] = that.ticks[1];
        }, (1000 / 60));

        // Listen to mouse moving
        window.document.addEventListener('mouseenter', function (e) {
            onMouseEnter.call(that, e);
        });

        // Listen to mouse leaving browser's window
        window.document.addEventListener('mouseleave', function (e) {
            onMouseLeave.call(that, e);
        });

        // Listen to mouse entering browser's window
        window.addEventListener('mousemove', function (e) {
            onMouseMove.call(that, e);
        });

        // There can be only one mousemeter
        delete window.Mousemeter;

        return that;
    };

    // Returns mouse's cursor coordinates as a JavaScript Object
    Mousemeter.prototype.getCoord = function () {
        return { x : this.coord[0], y : this.coord[1] };
    };

    // Returns mouse's cursor movement vector as a JavaScript Object
    Mousemeter.prototype.getVectr = function () {
        return { x : this.vectr[0], y : this.vectr[1] };
    };

    /**
     * Global scope hook
     */
    window.Mousemeter = Mousemeter;
}());

/**
 * WPOverlay a helper class to display 2 steps lightboxes to generate vouchers.
 *
 * First step is a e-mail form
 * 2nd step is the generated voucher display
 *
 * It should be used along with an "eligibility service", an endpoint where it can
 * send post request, endpoint should return an int :
 * -1 means no eligibility
 * 0 means that the lightbox should be printed immediatly plus eventual configured minimal delay
 * Any other integer higher than 0 will result in the lightbox to be displayed x seconds later
 * where x is the integer returned by the service
 *
 * The voucher service configured with the voucherService property of the rules given to WPOverlay
 * should return a JSON string formatted as follow :
 * {
 * 	result : The voucher,
 * 	success : A boolean indicating wether the voucher could be generated or not,
 * 	message : A error message indicating why the voucher could not be generated,
 *
 * }
 *
 * The class will emit several events during the whole process :
 * - lightbox_printed : the lightbox is displayed to the user
 * - step_1_completed : the user completed lightbox step one (usually submitting the e-mail address form)
 * - step_2_completed : the user completed lightbox step two (usually clicking the button of the step 2)
 *
 * About the lightbox :
 *
 * The HTML structure you can use is leaved free to one's appreciation,
 * though there are still some general guidelines :
 *
 * Lightbox container should be a bloc element, e-mail input should be within a form,
 * this form should not have a submit buttton, but a button element instead.
 *
 * Lightbox's first screen or step should be contained within a div with an attribute data-step of value "1"
 * Lightbox's second screen or step should be contained within a div with an attribute data-step of value "2"
 *
 * All jQuery selectors msut match a element inside the given container,
 * so the selector must be relative to the given lightbox container
 *
 * @param  object rules A set of rules defining conditions for overlay to print
 *
 * {
 * 	eligibilityService: {
 * 		url : the url to query to know if and when the visitor is eligible to the overlay,
 * 		parameters : { Extra parameters to pass along with the request to the service }
 * 	},
 * 	minDelay : minimum time to wait before showing the lightbox (in ms),
 * 	cookieName : name of the cookie to create at lightbox print ('wShop_OverlayExit' by default),
 * 	cookieDuration : cookie lifetime (one week by default),
 * 	lightbox : {
 * 		container : selector to the lightbox container,
 * 		shad : selector to the opacity layer "lightbox shadow" ('#shad' by default)
 * 	},
 * 	voucherService: {
 * 		url : the url to query to fetch the voucher,
 * 		parameters : {
 * 			Extra parameters to pass along with the request to the service,
 * 			properties should be jQuery selectors to the form inputs,
 * 			if an element targeted by a selector can't be found then the value
 * 			is not considered as a jQuery selector and passed as it is.
 * 			jQuery selectors given here, contrary to the other can be "absolute"
 * 		}
 * 	},
 * 	,voucherTarget : target element of the voucher value within the lightbox
 * 	emailForm : jQuery selector to the form containing the e-mail field
 * 				the user has to fill to get his voucher
 * }
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
WPOverlay = function(rules) {
    this.rules = rules;
    if (typeof this.rules.minDelay == 'undefined') {
        this.rules.minDelay = 5000;
    }

    if (typeof this.rules.cookieName == 'undefined') {
        this.rules.cookieName = 'wShop_OverlayExit';
    }

    if (typeof this.rules.cookieDuration == 'undefined') {
        this.rules.cookieDuration = 7 * 24 * 60 * 60 * 1000;
    }

    if (typeof this.rules.voucherTarget == 'undefined') {
        console.error('WPOverlay : Please specify a target in which insert the voucher value');
    }

    if (typeof this.rules.lightbox != 'undefined' &&
        typeof this.rules.lightbox.shad == 'undefined') {
        this.rules.lightbox.shad = '#shad';
    }

    if (typeof this.rules.onExit == 'undefined') {
        this.rules.onExit = true;
    }

    if (typeof this.rules.emailForm == 'undefined') {
        this.rules.emailForm = 'form.email-form';
    }
};

WPOverlay.prototype = Object.create(Emitter.prototype);

WPOverlay.prototype.getEligibility = function() {
    var serviceCallArgs = [this.rules.eligibilityService.url];

    if (this.rules.eligibilityService.parameters) {
        serviceCallArgs.push(this.rules.eligibilityService.parameters);
    }
    return $.post.apply(this, serviceCallArgs);
};

WPOverlay.prototype.showOverlay = function() {
    if (this.rules.eligibilityService && this.rules.eligibilityService.url) {
        this.getEligibility().done(function(overlayDelay) {
            if (overlayDelay >= 0) {
                setTimeout(function () {
                    if (this.rules.onExit) {
                        var meter = new Mousemeter(),
                        opened = false;
                        window.addEventListener('mousemove', function () {
                            if (opened == false && meter.getCoord().y < 45 && meter.speed > 175 && meter.getVectr().y < 0) {
                                opened = true;
                                this.showLightBox();
                            }
                        }.bind(this));
                    } else {
                        this.showLightBox();
                    }
                }.bind(this), overlayDelay == 0 ? this.rules.minDelay : overlayDelay * 1000);
            }
        }.bind(this));
    } else {
        console.error('WPOverlay : No rules have been defined for the eligibility service.');
        console.error('WPOverlay : Please define the property eligibilityService.url within the rules.');
        return false;
    }
};


WPOverlay.prototype.showLightBox = function() {
    if (typeof this.rules.lightbox != 'undefined') {
        var $lighbox = $(this.rules.lightbox.container),
            $shad = $(this.rules.lightbox.shad),
            cookieExpires = new Date();
        cookieExpires.setTime(cookieExpires.getTime() + this.rules.cookieDuration);
        document.cookie = this.rules.cookieName+"=1;expires=" + cookieExpires.toGMTString() + ";path=/";

    	$(this.rules.lightbox.shad+","+this.rules.lightbox.container+" a.close")
            .show()
            .on('click', function(e){
                e.preventDefault();
                $shad.hide();
                $lighbox.hide();
            });
    	$lighbox.show();
        this.emit('lightbox_printed');
    	$lighbox.find(this.rules.emailForm).submit(function(e) {
            this.emit('step_1_completed');
            e.preventDefault();
            if (typeof this.rules.voucherService != 'undefined' &&
                typeof this.rules.voucherService.url != 'undefined') {
                this.getVoucher(e).done(function(data) {
                    if (typeof data != 'object') {
                        data = JSON.parse(data);
                    }
                    if (data.success) {
                        $lighbox.find(this.rules.voucherTarget).html(data.result);
                        $lighbox.find('div[data-step="1"]').slideUp();
                        $lighbox.find('div[data-step="2"]').slideDown();
                        $lighbox.find('span#cp').html(data.result);
                        $lighbox.find('div[data-step="2"] button').click(function(e) {
                            this.emit('step_2_completed');
                            e.preventDefault();
                            $shad.hide();
                            $lighbox.hide();
                        }.bind(this));
                    } else {
                        $lighbox.find('p.error').html(data.message);
                    }
                }.bind(this));
            }
        }.bind(this));
    	$lighbox.find(this.rules.emailForm+' button').click(function(e) {
            e.preventDefault();
            $(e.currentTarget).parent().submit();
        });
    } else {
        console.error('WPOverlay : Lightbox container and lightbox shadow layer selectors have not been defined.');
        console.error('WPOverlay : Please define lightbox container and shadow user lightbox.shad and lightbox.container properties (see doc).');
        return false;
    }
};

WPOverlay.prototype.getVoucher = function(e) {
    var     postData = {};

    if (typeof this.rules.voucherService.parameters == 'object') {
        for (var property in this.rules.voucherService.parameters) {
            if ($(this.rules.voucherService.parameters[property]).length > 0) {
                postData[property] = $(this.rules.voucherService.parameters[property]).val();
            } else {
                postData[property] = this.rules.voucherService.parameters[property];
            }

        }
    }
    return $.ajax({
            type: 'post',
            data: postData,
            url: this.rules.voucherService.url
    });
};
