/**
 * Contains class that provide some useful methods
 * like hash computing, number notations conversions etc
 *
 * @author Sébastien Vray sv@webpopulation.com
 */

function    WPHash() {}

/**
 * sum Sums up the values of the characters within the given string
 *
 * WARNING: high risk of collision, this function is not cryptographically safe
 * use it only to generate keys in hashtables
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
WPHash.prototype.sum =
    function (string) {
        var  hash = 0;
        if (!(string instanceof String)) {
            string = new String(string);
        }
        for (var i = 0;i < string.length;i++) {
            hash = string.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
        }
        return this.toHex(Math.abs(hash));
    };

/**
 * toHex Converts a décimal number to hexadecimal notation
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
WPHash.prototype.toHex =
    function (number) {
        var  hex = '0123456789abcdef';
        var  hexHash = '';

        while (number > 16) {
            hexHash += hex[number % 16];
            number = parseInt(number / 16);
        }
        if (number > 0) {
            hexHash += number;
        }
        hexHash = hexHash.split('').reverse().join('');
        return hexHash;
    };


/**
 * _Service Provides method for registering and returning
 * Class instances that should be available anywhere in the app
 *
 * @return {[type]} [description]
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
function    _Service() {
    this.services = [];
    this.instances = [];
}

/**
 * register Registers a service
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
_Service.prototype.register =
    function (name) {
        this.services.push({n: name, a: Array.prototype.splice.call(arguments, 1, arguments.length)});
    };

/**
 * remove De-register a service
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
_Service.prototype.remove =
    function (name) {
        for (var i = 0;i < this.services.length;i++) {
            if (this.services[i].n === name) {
                this.services.splice(i, 1);
            }
        }
    };

/**
 * get Returns the instance of the given service or
 * instanciates the given service and returns the instance
 *
 * @author Sébastien Vray sv@webpopulation.com
 */
_Service.prototype.get =
    function (name) {
        for (var i = 0;i < this.instances.length;i++) {//Search for an existing instance of the given service
            if (this.instances[i].n === name) {
                return this.instances[i].o;
            }
        }
        var instance = null;
        for (var i = 0;i < this.services.length;i++) {//If no instance were found then search for the registered service
            if (this.services[i].n === name) {//When found
                //Instanciate
                if (this.services[i].a.length > 0) {//Does the constructor have any args registered?
                    var constructorParameters = this.services[i].a;
                    if (typeof constructorParameters !== 'object') {//Is the registered args are a scalar value
                        constructorParameters = [constructorParameters];//Apply needs an array of args
                    }
                    //Since we cannot call apply on a constructor we have
                    //to call apply on bind with the constructor and the array of arguments
                    //then call bind on the constructor itself and pass the result to the 'new' keyword
                    //first argument in the array passed to apply must be null because this actually correspond
                    //to the first bind argument, other wise the first argument would not be passed to the service constructor
                    instance = new (this.services[i].n.bind.apply(this.services[i].n, [null].concat(constructorParameters)));
                } else {
                    instance = new this.services[i].n();
                }
                this.instances.push({
                    n: this.services[i].n,
                    a: this.services[i].a,
                    o: instance
                });
            }
        }
        return instance;
    };

    /**
     * Service This is a wrapper function for the service provider class
     *
     * @author Sébastien Vray sv@webpopulation.com
     */
    function    Service() {
        return (function(args) {
            if (args.length === 0) {
                if (typeof this.service === 'undefined') {
                    this.service = new _Service();//Instanciate service provider, this is a singleton
                }
                return this.service;
            } else if (args.length > 0 && typeof this.service !== 'undefined') {
                return this.service.get(args[0]);//If arguments where provided, fetch the corresponding service
            }
        })(arguments);//Hide the services provider logic from the outside
    };


    String.prototype.base64 = function() {
        return {
            decode: function() {
                var b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
                var o1, o2, o3, h1, h2, h3, h4, bits, i = 0,
                ac = 0,
                dec = '',
                tmp_arr = [];
                var data = this;
                data += '';

                do { // unpack four hexets into three octets using index points in b64
                    h1 = b64.indexOf(data.charAt(i++));
                    h2 = b64.indexOf(data.charAt(i++));
                    h3 = b64.indexOf(data.charAt(i++));
                    h4 = b64.indexOf(data.charAt(i++));

                    bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;

                    o1 = bits >> 16 & 0xff;
                    o2 = bits >> 8 & 0xff;
                    o3 = bits & 0xff;

                    if (h3 == 64) {
                        tmp_arr[ac++] = String.fromCharCode(o1);
                    } else if (h4 == 64) {
                        tmp_arr[ac++] = String.fromCharCode(o1, o2);
                    } else {
                        tmp_arr[ac++] = String.fromCharCode(o1, o2, o3);
                    }
                } while (i < data.length);
                dec = tmp_arr.join('');
                return dec.replace(/\0+$/, '');
            }.bind(this),
            encode : function () {
                var b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
                var output = "";
                var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
                var i = 0;

                while (i < this.length) {

                    chr1 = this.charCodeAt(i++);
                    chr2 = this.charCodeAt(i++);
                    chr3 = this.charCodeAt(i++);

                    enc1 = chr1 >> 2;
                    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                    enc4 = chr3 & 63;

                    if (isNaN(chr2)) {
                        enc3 = enc4 = 64;
                    } else if (isNaN(chr3)) {
                        enc4 = 64;
                    }

                    output = output +
                    b64.charAt(enc1) + b64.charAt(enc2) +
                    b64.charAt(enc3) + b64.charAt(enc4);
                }
                return output;
            }.bind(this)
        };
    };
