if (!Object.create) {
    Object.create = (function(){
        function F(){}

        return function(o){
            if (arguments.length != 1) {
                throw new Error('Object.create implementation only accepts one parameter.');
            }
            F.prototype = o;
            return new F();
        };
    })();
}

/*
 * Tooltip object
 *
*/
function WPTooltip() {};

WPTooltip.prototype = {
	settings : {
            tooltipId:"bloc_erreur",
            openAnimation: "size",
            closeAnimation: "slide",
            openClick: false,
            openSpeed: 0,
            closeSpeed: 0,
            closeDelay: 4000,
            width: 270,
            tooltipHeight: 0,
            maxWidth: 450,
            offsetLeft: 10,
            offsetTop: 10,
            zindex: 100,
            tooltipArrowPosition : 'left',
            arrowCenterOffset: 2,
            arrowOffsetLeft: 3,
            outOfScreenArrowOffset : 40,
            tooltipPosition: 'top'
        },
    tooltipOn : false,
	tooltip : null,
    timerTooltip : null
};

WPTooltip.prototype.init = function(settings) {
    this.initTooltip(settings);
};

WPTooltip.prototype.getTooltip = function() {
    return $(this.tooltip);
};
WPTooltip.prototype.getSettings = function() {
    return this.settings;
};

WPTooltip.prototype.initTooltip = function(settings) {
    if (typeof settings != "undefined") {
        for (var i in settings) {
            this.settings[i] = settings[i];
        }
    }
    if( typeof(mobileApi) != 'undefined' && mobileApi === true ){
       this.settings.openSpeed = 500;
       this.settings.closeSpeed = 500;
    }else{
        this.tooltip = $("#" + this.settings.tooltipId);
        $("body").append($(this.tooltip));
        this.getTooltipHeight();
        this.clearAllTimers();
        this.addTooltipListeners();
    }
};

WPTooltip.prototype.getTooltipHeight = function() {
    var height;
    this.tooltip.show();
    height = this.tooltip.height();
    this.tooltip.hide();
    this.setOption('tooltipHeight', height);
    return height;
};

/*
 * clear all timers
*/
WPTooltip.prototype.clearAllTimers = function() {
    if (typeof(timer) && timer) {
            clearTimeout(timer);
            timer = null;
        }
        if (typeof(tooltip_counter) != "undefined" && tooltip_counter) {
            clearTimeout(tooltip_counter);
            tooltip_counter = null;
        }
        if (this.timerTooltip) {
            clearTimeout(this.timerTooltip);
            this.timerTooltip = null;
        }
};

/*
 * fix tooltip left offset
*/
WPTooltip.prototype.fixOffsetLeft = function (elem) {
    var nodeName = "";
    var elm = $("#" + elem);
    try {
        nodeName = $(elm).get(0).nodeName;
    } catch (e) {}
    if (nodeName == "IMG") {
        return - ($(elm).width() / 2 + this.settings.arrowOffsetLeft - this.settings.offsetLeft);
    }
    if (nodeName == "SELECT" && $("#" + elem).hasClass('forceBottomTooltip') ) {
        return $(elm).width();
    }
    if (nodeName == "A") {
        return 0;
    }
    return this.settings.offsetLeft;
};


WPTooltip.prototype.repportError = function (message) {
    // console.log(message);
};
/*
 * fix tooltip top offset
*/
WPTooltip.prototype.fixOffsetTop = function (elem) {
    var nodeName = "";
    var elm = $("#" + elem);

    try {
        nodeName = $(elm).get(0).nodeName;
    } catch (e) {
        this.repportError(e);
    }
    if (nodeName == "IMG") {
            return 0;
    }
    if (nodeName == "A") {
            return 5;
    }
    if (this.settings.tooltipPosition == 'bottom') {
        this.setOption('offsetTop', this.getTooltipHeight() + $("#" + elem).height() + 8);
    }

    return this.settings.offsetTop;
};

/*
 * show a tooltip
 * @params
 *      String elem - id of the elemement
 *          where the tooltip will be positioned
 *      boolean autohide - the tooltip will dissapear
 *          if the value is true ( see settings.closeDelay)
 *      String message - the message showed in tooltip
 *      Integer offLeft, offTop - values to adjust the position of the tooltip
*/
WPTooltip.prototype.showTooltip = function (elem, autohide, message, offLeft, offTop) {
    var that = this;
    var $elem = $("#" + elem);
    if( typeof(mobileApi) != 'undefined' && mobileApi === true ){
        that.tooltipOn = true;
        /*if( $elem.parent().find($('.error_mobile')).length == 0){
            $("#" + elem).after('<div class="error_mobile"></div>');
            that.tooltip = $elem.parent().find($('.error_mobile'));
        }

        that.tooltip.html(message);

        if (! that.tooltipOn) {
           $(that.tooltip).slideDown(this.settings.openSpeed, function () {that.tooltipOn = true;});
        }*/

    }else{
        $('#erreur_msg').html(message);

        if (typeof offLeft == "undefined") {
            offLeft = this.fixOffsetLeft(elem);
        }
        if (typeof offTop == "undefined") {
            offTop = this.fixOffsetTop(elem);
        }
        this.stopTimer();
        this.tooltip.width(this.settings.width);
        this.tooltip.show(this.settings.openSpeed);
        $("#erreur_msg").css("white-space", "normal");
        // if ($("#erreur_msg").width() > this.settings.maxWidth) {
        //     $("#erreur_msg").width(this.settings.maxWidth);
        // }
        var isTooltipImg = false;
        var nodeName = "";

        try {
            nodeName = $("#" + elem).get(0).nodeName;
        } catch (e) {
            this.repportError(e);
        }
        if (nodeName == "IMG") {
            isTooltipImg = true;
        }
        try {
            var elemOffset = $("#" + elem).offset();
            var topPosition =  elemOffset.top - this.tooltip.height() - 5 + offTop;
            var leftPosition = elemOffset.left + offLeft - 1;
        } catch (e) {
            this.repportError(e);
            return false;
        }


        this.tooltip.find("td.bottom_tooltip").css("overflow", "hidden");
        this.tooltip.find("td.bottom_tooltip img").css("float", "left");
        switch (this.settings.tooltipArrowPosition) {
            case 'center':
                var arrow = this.tooltip.find("td.bottom_tooltip img");
                arrow.css("marginLeft", parseInt(this.tooltip.width() / 2 - arrow.width() / 2 - this.settings.arrowCenterOffset));
                leftPosition -= parseInt(this.tooltip.width() / 2 + arrow.width() / 2 + this.settings.arrowCenterOffset) ;
                topPosition -= 5;
            break;
        }

        this.tooltip.find("td.bottom_tooltip img").css("margin-top", "-3px");

        var isOutOfScreen = (parseInt(leftPosition) + parseInt(this.tooltip.width())) > $(window).width();
        if (isOutOfScreen && isTooltipImg) {
            leftPosition = elemOffset.left + this.settings.outOfScreenArrowOffset - this.tooltip.width();
            this.tooltip.find("td.bottom_tooltip img").css("float", "right");
        }
        this.tooltip.css({ position : 'absolute', top : topPosition, left: leftPosition , zIndex : '9999'});

        if (! that.tooltipOn) {
           $(this).fadeIn(this.settings.openSpeed, function () {that.tooltipOn = true;});
        }

        if (autohide) {
            this.startTimer();
        }
    }
};

/*
 * add tooltip listeners
*/
WPTooltip.prototype.addTooltipListeners = function() {
    var that = this;
    this.tooltip.off();
    this.tooltip.mouseenter(function(e) {
        that.stopTimer();
    });
    this.tooltip.mouseleave(function(e) {
        that.startTimer();
    });
};

/*
 * hide tooltip
*/
WPTooltip.prototype.hideTooltip = function () {
    this.stopTimer();
    if(this.tooltipOn){
        if( typeof(mobileApi) != 'undefined' && mobileApi === true ){
            $(this.tooltip).slideUp(this.settings.closeSpeed);
        }else{
            $(this.tooltip).hide(this.settings.closeSpeed);
        }
        this.tooltipOn = false;
    }
};

/*
 * start the tooltip close timer
*/
WPTooltip.prototype.startTimer = function() {
    var that = this;
    this.stopTimer();
    this.timerTooltip = setTimeout(function() {that.hideTooltip();}, that.settings.closeDelay);
};

/*
 * stop and clear the timer
*/
WPTooltip.prototype.stopTimer = function() {
    try {
        clearTimeout(this.timerTooltip);
        this.timerTooltip = null;
    } catch (e) {
        this.repportError(e);
    }
};

/*
 * remove the error icon and restore the element css class
 * @params
 *      String elementId - id of the elemement
 *          where the error has found
 */
WPTooltip.prototype.clearError = function(elementId) {

    var $elementId = $('#'+elementId);
    var $target = $elementId.parent();
    if($elementId.is('select')){
        $target = $elementId.parent().parent();
    }
    var $iconErr = $('#iconErr'+elementId) || $target.children("img").not('.valid_form');
    var $targetNewClass = $elementId;
    if($elementId.is('select')){
        $targetNewClass = $elementId.siblings('.select,.clone');
    }
    if (! $targetNewClass.hasClass("inputForm")) {
        $targetNewClass.addClass("inputForm");
    }

    $targetNewClass.removeClass('inputErr');
    $('#'+elementId + 'Label').removeClass('labelErr');

    if($elementId.is('select')){
        $targetNewClass.siblings('select').off('mouseenter mouseleave');
    }else{
        $iconErr.animate({opacity : 0},600,function(){
            $iconErr.remove();
        });

        if( typeof(activeValidWpApi) != 'undefined' && activeValidWpApi === true && $elementId.val().length >= 2 ){

            if($('#iconValid'+elementId).length == 0){
                var imgFile = 'img/';
                if( typeof(mobileApi) != 'undefined' && mobileApi === true ){
                    imgFile = 'img/mobile/';
                }
                var $newImgValid = $('<img />',{
                    'class' : 'valid_form',
                    'id' : 'iconValid'+elementId,
                    'src' : path_relative_root+imgFile+'picto_panneau_valid.png'
                }).css({opacity : 0});

                $elementId.after($newImgValid);
                $newImgValid.animate({opacity : 1},600);
            }
        }else{
            if($('#iconValid'+elementId).length > 0 && $elementId.val().length < 2){
                $('#iconValid'+elementId).animate({opacity : 0},600,function(){
                    $('#iconValid'+elementId).remove();
                });
            }
        }
    }
};

WPTooltip.prototype.clearAllErrors = function() {
    var that = this;
    setTimeout(function() {
    $('.inputErr, .labelErr').each(function () {
        that.clearError($(this).attr('id').replace('Label', ''));
    });
    }, 1000);

};

WPTooltip.prototype.getMessageWithTitle = function (elementId, errorMessage) {
    var labelText;
    try {
        var $content = $('#'+elementId + 'Label').html();
        $content = $content.replace("<br>", " ").replace(":", "");
        var labelContent = $('<div>' + $content + '</div>');
            labelContent = labelContent.text();
            labelContent = labelContent.replace("*", "");
        labelText = $.trim(labelContent).replace(' :','');
        var errorMessageObj = $('<div>' + errorMessage + '</div>');
        if (labelText != "" && errorMessageObj.find(".title_toolltip").length == 0) {
            errorMessage =  '<p class="title_toolltip">' + labelText + ' :</p>' + errorMessage;
        }
    } catch (e) {
        this.repportError(e);
    }
    return errorMessage;
}

/*
 * create a error icon and initialize a tooltip
 * to display the error message
 * @params
 *      String elementId - id of the elemement
 *          where the error has found
 *      String errorMessage - the message displayed in
 *          the generated tooltip
*/
WPTooltip.prototype.showError = function (elementId, errorMessage) {
    var that = this;
    var $elementId = $('#'+elementId);

    if( typeof(activeValidWpApi) != 'undefined' && activeValidWpApi === true ){
        if($('#iconValid'+elementId).length > 0){
            $('#iconValid'+elementId).animate({opacity : 0},600,function(){
                $('#iconValid'+elementId).remove();
            });
        }
    }
    var $targetNewClass = $elementId;
    if($elementId.is('select')){
        $targetNewClass = $elementId.siblings('.select,.clone');
        if( $targetNewClass.length == 0 )
            $targetNewClass = $elementId.parent().siblings('.select_item');

        $elementId.siblings('.clone').css('border', '1px solid #F00');
        $elementId.addClass('inputErr');
    }
    try {
        $targetNewClass.addClass('inputErr');
    } catch (e) {}

    try {
        $( $elementId + 'Label').addClass('labelErr');
    } catch (e) {}

    errorMessage = this.getMessageWithTitle(elementId, errorMessage);
    var $target = $elementId.parent();

    var $iconErr = $('#iconErr'+elementId) || $('img',$target).not('.valid_form');
    if(!$elementId.is('select')){
        var imgFile = 'img/';
        if( typeof(mobileApi) != 'undefined' && mobileApi === true ){
            imgFile = 'img/mobile/';
        }
        if($iconErr.length === 0) {

            $iconErr = $('<img />', {
                class: 'img_error',
                id: 'iconErr' + elementId,
                src: path_relative_root + imgFile + 'picto_panneau.png'
            }).css({opacity: 0});

            if ($elementId.parent().hasClass("intl-tel-input")) {

                $elementId.parent().after($iconErr);
            } else {

                $elementId.after($iconErr);
            }
        } else {
            this.unbindAll('iconErr' + elementId);
        }

        if ($("#iconErr"+elementId).length > 0) {
            $iconErr = $("#iconErr"+elementId);
        }

        if(!$("#iconErr"+elementId).is(':visible') || $("#iconErr"+elementId).css('opacity') == 0){
            $iconErr.stop().animate({opacity : 1},600);
        }

    }
    var timerOut;
    if ($iconErr.length) {
        if( typeof(mobileApi) != 'undefined' && mobileApi === true ){
           that.showTooltip($elementId.prop("id"), false, that.getMessageWithTitle($elementId.prop("id"), errorMessage));
        }else{
            $iconErr.mouseenter(function() {
                clearTimeout(timerOut);
                try {
                    that.showTooltip($(this).prop("id"), false, that.getMessageWithTitle($(this).prop("id"), errorMessage));
                } catch (e) {
                    this.repportError(e);
                }
            });
            $iconErr.mouseout(function() {
               timerOut = setTimeout(function(){
                   that.hideTooltip();
               },350);
            });
        }
        }else if($elementId.is('select')){
        $targetNewClass.siblings('select').on({
            'mouseenter':function() {
                clearTimeout(timerOut);
                try {
                    that.showTooltip($(this).prop("id"), false, that.getMessageWithTitle($(this).prop("id"), errorMessage));
                } catch (e) {
                    this.repportError(e);
                }
            },'mouseleave':function() {
               timerOut = setTimeout(function(){
                   that.hideTooltip();
               },350);
            }
        });
    }
};
