(function(window, $) {
    'use strict';

    function GoogleEC() {
    }

    GoogleEC.traceException = function(e) {
        e = new Error("["+(new Date().toString())+"] Error in GoogleEC.js : "+e);
        if (typeof e.stack != "undefined") {
            e.message += "\r\nstack : "+e.stack;
        }
        var additionalData = [];
        var argumentValueBackup;
        for (var i = 1;i < arguments.length;i++) {
            if (typeof arguments[i] == 'object') {
                try {
                    argumentValueBackup = arguments[i];
                    arguments[i] = JSON.stringify(arguments[i]);
                } catch (e) {
                    arguments[i] = argumentValueBackup;
                }
            }
            additionalData.push(arguments[i]);
        }
        e.message += "\r\nData : \r\n"+additionalData.join("\r\n\r\n");
        ga('send', 'exception', {
            'exDescription': e.message,
            'exFatal': false
        });
        $.post(window.document.location.protocol+'//www.tati.fr/track.php', {transactionTrackException: e.message});
        //$.post('http://localhost/wshop-front/tati.fr/htdocs/track.php', {transactionTrackException: e.message});
    };

    GoogleEC.traceExceptionSimple = function(e) {
        $.ajax({
          type: "POST",
          url: 'http://fo1.tati.webpop.fr/track.php',
          data: {transactionTrackException: 'EMPTY VALUE\r\nURL : ' + window.location + '\r\n' + e}
        });
        //$.post(window.document.location.protocol+'//fo1.tati.webpop.fr/track.php', {transactionTrackException: 'LIST EMPTY\r\nURL : ' + window.location + '\r\n' + e});
        //$.post('http://localhost/wshop-front/tati.fr/htdocs/track.php', {transactionTrackException: e.message});
    };

    /**
     * Searches the current page for product information
     * markup and set correct information for Google EC plugin
     *
     * @author Sébastien Vray sv@webpopulation.com
     */
    GoogleEC.setProductPrintInfo = function($target, data) {
        try {
            data = JSON.parse(data.base64().decode());
            if (!$target.prop('data-ec-sent')) {
                ga('ec:addImpression', {
                    id: data.id,
                    name: data.name,
                    list: data.list,
                    brand: data.brand,
                    variant: data.variant,
                    position: data.position,
                    price: data.price,
                    category: data.category
                });
            }
            $target.prop('data-ec-sent', true);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    GoogleEC.sendProductCartAddInfo = function($target, data, action, event, place, page) {
        try {
            if (data instanceof String) {
                data = JSON.parse(data.base64().decode());
            }
            ga('ec:addProduct', {
                id: data.id,
                name: data.name,
                quantity: data.quantity,
                brand: data.brand,
                variant: data.variant,
                position: data.position,
                price: data.price,
                category: data.category,
                coupon: data.coupon
            });
            ga('ec:setAction', 'add', {
                'list': place
            });
            var gaCall = [];
            for (var i = 2;i < arguments.length;i++) {
                gaCall.push(arguments[i]);
            }
            ga.apply(this, gaCall);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    GoogleEC.sendProductCartRemoveInfo = function($target, data, action, event, place, page) {
        try {
            data = JSON.parse(data.base64().decode());
            ga('ec:addProduct', {
                id: data.id,
                name: data.name,
                quantity: data.quantity,
                brand: data.brand,
                variant: data.variant,
                position: data.position,
                price: data.price,
                category: data.category,
                coupon: data.coupon
            });
            ga('ec:setAction', 'remove', {
                'list': place
            });
            var gaCall = [];
            for (var i = 2;i < arguments.length;i++) {
                gaCall.push(arguments[i]);
            }
            ga.apply(this, gaCall);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    GoogleEC.setCheckoutStep = function(step, data, action, event, place, page) {
        try {
            GoogleEC.addProducts(data);
            ga('ec:setAction', 'checkout', {
                'step': step,
                'list': place
            });
            var gaCall = [];
            for (var i = 2;i < arguments.length;i++) {
                gaCall.push(arguments[i]);
            }
            ga.apply(this, gaCall);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    GoogleEC.setCheckoutStepWOpt = function(step, opt, data, action, event, place, page) {
        try {
            GoogleEC.addProducts(data);
            ga('ec:setAction', 'checkout', {
                'step': step,
                'list': place,
                'option': opt
            });
            var gaCall = [];
            for (var i = 3;i < arguments.length;i++) {
                gaCall.push(arguments[i]);
            }
            ga.apply(this, gaCall);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    GoogleEC.sendTransaction = function(data, products, action, event, place, page) {
        try {
            GoogleEC.addProducts(products);
            ga('ec:setAction', 'purchase', {
                'id': data.id,
                'revenue': data.revenue,
                'tax': data.tax,
                'shipping': data.shipping,
                'coupon': data.coupon,
                'list': 'Fiche Produit'
            });            
            var gaCall = [];
            for (var i = 2;i < arguments.length;i++) {
                gaCall.push(arguments[i]);
            }
            ga.apply(this, gaCall);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    GoogleEC.addProducts = function(data) {
        for (var i = 0;i < data.length;i++) {
            ga('ec:addProduct', {
                id: data[i].id,
                name: data[i].name,
                quantity: data[i].quantity,
                brand: data[i].brand,
                variant: data[i].variant,
                position: data[i].position,
                price: data[i].price,
                category: data[i].category,
                coupon: data[i].coupon
            });
        }
    };

    GoogleEC.sendPromotionPrint = function($target, data) {
        try {
            data = JSON.parse(data.base64().decode());
            if (!$target.prop('data-ec-sent')) {
                ga('ec:addPromo', {
                    id : data.segment,
                    name: data.name,
                    creative: $target.attr('title'),
                    position: data.position
                });
            }
            $target.prop('data-ec-sent', true);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    GoogleEC.sendPromotionClick = function($target, data) {
        try {
            $target.removeAttr('data-ec-sent');
            $target.prop('data-ec-sent', null);
            GoogleEC.sendPromotionPrint($target, $target.attr('data-ec'));
            ga('ec:setAction', 'promo_click');
            var gaCall = [];
            for (var i = 2;i < arguments.length;i++) {
                gaCall.push(arguments[i]);
            }
            ga.apply(this, gaCall);
        } catch (e) {
            console.log(e);
            GoogleEC.traceException(e, data);
        }
    };

    $.fn.GoogleEC = function() {
        var jq = this;
        return {
            sendProductPrintInfo: function() {
                jq.each(function(i, obj) {
                    if (typeof obj.dataset != 'undefined' && typeof obj.dataset['no-send'] == 'undefined') {
                        /*GoogleEC.setProductPrintInfo($(obj), obj.dataset.ec);
                        if ((i % 20 === 0 && i != 0) || i === jq.length - 1) {//Limit request size to 20 impression items
                            ga('send', 'event', 'Products', 'impression', 'Send displayed product information', {
                                nonInteraction: true
                            });
                        }*/
                    }
                });
            },
            cartAdd: function() {
                jq.each(function(i, obj) {
                    if (typeof obj.dataset != 'undefined') {
                        $(obj).on('click', function() {
                            GoogleEC.sendProductCartAddInfo($(obj), obj.dataset.ec);
                        }.bind(this));
                    }
                });
            },
            trackPromotions: function() {
                jq.each(function(i, obj) {
                    if (typeof obj.dataset != 'undefined') {
                        GoogleEC.sendPromotionPrint($(obj), obj.dataset.ec);
                        $(obj).on('click', function(e) {
                            var gaCall = [$(e.currentTarget), $(e.currentTarget).attr('data-ec')].concat((e.currentTarget['ec-callback-args'] || []));
                            if (gaCall.length > 2) {
                                GoogleEC.sendPromotionClick.apply(this, gaCall);
                            }
                        }.bind(this));
                    }
                });
                ga('send', 'event', 'Promos', 'print', 'Coupons print', {
                    nonInteraction: true
                });
            }
        };
    };

    /**
     * function Sets clicked product informations in GA EC and send GA event using
     * functions passed arguments
     *
     * Usage :
     * onProductClick(productElement, 'send', 'event', 'Event category', 'Event Action', 'Event label');
     *
     *
     * @author Sébastien Vray sv@webpopulation.com
     */
    GoogleEC.onProductClick = function() {
        if (arguments.length > 0 && typeof arguments[0].dataset != 'undefined') {
            var $product = $(arguments[0]);
            var data = arguments[0].dataset.ec.base64().decode();
            try {
                data = JSON.parse(data);
                ga('ec:addProduct', {
                    id: data.id,
                    name: data.name,
                    quantity: data.quantity,
                    brand: data.brand,
                    variant: data.variant,
                    position: data.position,
                    price: data.price,
                    category: data.category,
                    coupon: data.coupon
                });
                ga('ec:setAction', 'click', {
                    list: data.list
                });
                if (arguments.length > 1) {
                    var gaCall = [];
                    for (var i = 1;i < arguments.length;i++) {
                        gaCall.push(arguments[i]);
                    }
                    ga.apply(this, gaCall);
                }
            } catch (e) {
                console.log(e);
                GoogleEC.traceException(e, data);
            }
        }
    };

    GoogleEC.onProductView = function(productData) {
        ga('ec:addProduct', {
           id: productData.id,
           name: productData.name,
           quantity: productData.quantity,
           brand: productData.brand,
           variant: productData.variant,
           position: productData.position,
           price: productData.price,
           category: productData.category,
           coupon: productData.coupon
        });
        ga('ec:setAction', 'detail', {
           list: productData.list
        });
        ga('send', 'event', 'Products', 'Display', 'Details', {
            nonInteraction: true
        });
    };

    window.GoogleEC = GoogleEC;
})(window, $);
